<template>
    <div id="app">
        <BackgroundGraphics v-show="$vuetify.breakpoint.smAndUp" />
        <v-app>
            <v-main fluid fill-height>
                <div class="d-flex justify-space-between">
                    <v-select
                        class="langSelect"
                        hide-details
                        style="max-width:80px;"
                        :menu-props="{ bottom: true, offsetY: true }"
                        solo
                        flat
                        background-color="transparent"
                        append-icon="mdi-earth"
                        v-model="$i18n.locale"
                        :items="supportedLanguages"
                        @change="langHandler"
                    >
                        <template v-slot:item="data">
                            <span class="text-uppercase">{{ data.item }}</span>
                        </template>
                        <template v-slot:selection="data">
                            <span class="text-uppercase">{{ data.item }}</span>
                        </template>
                    </v-select>

                    <!-- youtube signup tour (only in DE) -->
                    <v-btn
                        v-if="$i18n.locale === 'de'"
                        text
                        color="primary"
                        @click="videoDialog = true"
                        class="mt-1"
                    >
                        <v-icon class="mr-1">mdi-play</v-icon>tour
                    </v-btn>
                </div>

                <v-container class="pa-0">
                    <router-view />

                    <v-btn
                        v-scroll="onScroll"
                        v-show="fab && $vuetify.breakpoint.smAndUp"
                        fab
                        dark
                        fixed
                        bottom
                        right
                        color="primary"
                        @click="toTop"
                    >
                        <v-icon>keyboard_arrow_up</v-icon>
                    </v-btn>
                </v-container>
            </v-main>
            <!-- contact us footer -->

            <div v-if="$route.name === 'questions'">
                <v-footer padless color="#FDEEF1" class="mt-7">
                    <v-container>
                        <div class="text-center pa-5">
                            {{ $t("contact.notSure") }}
                            <br />



                            <i18n path="contact.willHelp">
                                <a
                                    class="text-decoration-none font-weight-bold"
                                    target="_blank"
                                    :href="contactLink"
                                >
                                    {{ $t("contact.contactLink") }}
                                </a>
                            </i18n>
                            <span class="text--disabled float-left">{{ VERSION }}</span>
                        </div>
                    </v-container>
                </v-footer>
            </div>
        </v-app>

        <!-- video dialog -->
        <v-dialog v-model="videoDialog" max-width="700">
            <v-card class="videoDialog">
                <v-card-title>Teambarometer signup tour</v-card-title>
                <v-card-text>
                    <div class="mb-2">
                        Lass dir von Marta zeigen, wie einfach das Aufsetzen
                        einer Umfrage ist
                    </div>
                    <youtube video-id="eANr4GH2Li4" ref="youtube" />
                    <v-btn
                        depressed
                        color="primary"
                        @click="videoDialog = false"
                        >OK</v-btn
                    >
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import version from "../version.js";
import { getTopCategories } from "@/api/catalog.api.js";
import BackgroundGraphics from "@/components/BackgroundGraphics";
import { supportedLangs } from "@/plugins/i18n.js";
import { VERSION } from "@/utils/globalconsts.js";
export default {
    props: [""],
    components: { BackgroundGraphics },
    data: () => ({
        fab: false,
        supportedLanguages: supportedLangs,
        videoDialog: false,
        VERSION: VERSION,
    }),
    mounted() {
        // retrieve main categories for question picker and save them in Vuex
        getTopCategories().then(
            response => {
                this.$store.dispatch(
                    "questionpicker/setCategories",
                    response.data
                );
            },
            error => {
                console.log(error);
            }
        );
        document.documentElement.lang = this.$i18n.locale; //set html meta lang tag
    },
    methods: {
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.fab = top > 100;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        langHandler() {
            this.$router.replace({
                params: { lang: this.$i18n.locale },
                query: this.$route.query
            });
            this.$store.commit("setUserLanguage", this.$i18n.locale);
            document.documentElement.lang = this.$i18n.locale;
        }
    },
    computed: {
        userLang() {
            return this.$store.state.newUser.language;
        },
          contactLink(){
            return this.$store.getters.contactLink
        },
               definedSets(){
            return this.$store.state.questionpicker.definedSets
        },
    },
    watch: {
        videoDialog(val) {
            if (!val) {
                this.$refs.youtube.player.pauseVideo();
            }
        }
    }
};
</script>

<style>
@media only screen and (min-width: 960px) {
    .container {
        max-width: 95% !important;
    }
}

#app,
body {
    font-family: "Lato", sans-serif !important;
    color: #25292e;
}

.desktopLang {
    position: absolute;
    right: 0;
}

.versionNr {
    text-align: right;
    font-size: 10px;
    color: grey;
}
</style>
