<template>
  <!-- new user's primary details (team step 3) -->
    <v-stepper-content step="3" class="pl-6 pr-0 mx-0">
        <div v-if="members && members.length > 0" class="mb-10">
            <p class="text-button">
                <v-icon class="mt-n1">mdi-account-group</v-icon>
                {{ $t("team.addedMembers") }}
            </p>
            <v-divider></v-divider>

            <div v-for="(member, i) in members" :key="i">
                <v-list-item>
                    <v-list-item-avatar class="white--text" color="#FFC107">
                        {{ member.firstName[0].toUpperCase()
                        }}{{ member.lastName[0].toUpperCase() }}
                    </v-list-item-avatar>

                    <v-list-item-content style="word-wrap: break-word">
                        {{ member.firstName }}
                        {{ member.lastName }}
                        {{ member.email }}
                    </v-list-item-content>

                    <v-list-item-icon class="align-self-center">
                        <v-btn icon @click="removeMember(i)">
                            <v-icon color="grey">
                                remove_circle_outline
                            </v-icon>
                        </v-btn>
                    </v-list-item-icon>
                </v-list-item>
                <v-divider></v-divider>
            </div>
        </div>
        <v-card-text class="pa-0">
            <v-row v-show="!showFields" class="mt-3" no-gutters>
                <v-col
                    style="max-width: 350px;"
                    :class="{
                        'border-right': $vuetify.breakpoint.mdAndUp,
                        'mb-10': $vuetify.breakpoint.xsOnly
                    }"
                    class="text-center"
                    cols="12"
                    sm="6"
                    md="6"
                >
                    <v-img
                        class="fade-in d-flex mx-auto mb-1"
                        :src="require(`@/assets/images/add.png`)"
                        max-width="100"
                        contain
                    ></v-img>

                    <base-btn
                        @click="showFields = true"
                        :label="$t('team.addNow')"
                        class="mb-2"
                    />

                    <div class="px-2">{{ $t("team.prepare") }}</div>
                </v-col>

                <v-col
                    style="max-width: 350px"
                    class="text-center"
                    cols="12"
                    sm="6"
                    md="6"
                >
                    <v-img
                        class="fade-in d-flex mx-auto mb-1"
                        :src="require(`@/assets/images/next.png`)"
                        max-width="100"
                        contain
                    ></v-img>

                    <v-tooltip
                        bottom
                        :disabled="readyForInterval"
                        color="transparent"
                    >
                        <template v-slot:activator="{ on }">
                            <div v-on="on">
                                <base-btn
                                    @click="next"
                                    :disabled="!readyForInterval"
                                    :label="$t('team.addLater')"
                                    class="mb-2"
                                />
                            </div>
                        </template>
                        <v-card dark color="grey darken-1">
                            <v-card-text>
                                {{ $t("team.fillSteps") }}
                            </v-card-text>
                        </v-card>
                    </v-tooltip>

                    <div class="px-5">{{ $t("team.canAdd") }}</div>
                </v-col>
            </v-row>
        </v-card-text>

        <div v-show="showFields">
            <v-form v-model="isFormValid" ref="form">
                <v-text-field
                    :rules="notEmpty"
                    v-model="firstName"
                    :label="$t('form.firstName')"
                    prepend-icon="person"
                ></v-text-field>
                <v-text-field
                    :rules="notEmpty"
                    v-model="lastName"
                    :label="$t('form.lastName')"
                    prepend-icon="person"
                ></v-text-field>
                <v-text-field
                    :rules="emailRules"
                    v-model="email"
                    :label="$t('form.email')"
                    prepend-icon="email"
                >
                    <template slot="append">
                        <v-tooltip bottom max-width="250" color="transparent">
                            <template v-slot:activator="{ on }">
                                <v-icon color="info" v-on="on">
                                    mdi-information-outline
                                </v-icon>
                            </template>

                            <v-card dark color="grey darken-1">
                                <v-card-text>
                                    {{ $t("team.noEmail") }}
                                </v-card-text>
                            </v-card>
                        </v-tooltip>
                    </template>
                </v-text-field>
                <v-slide-y-transition>
                    <v-alert
                        v-if="emailExists"
                        type="error"
                        outlined
                        text
                        dense
                        color="error"
                        >{{ $t("team.emailRegistered") }}
                    </v-alert>
                </v-slide-y-transition>
            </v-form>

            <v-btn
                depressed
                color="primary"
                @click="saveMember"
                :disabled="!isFormValid || emailExists"
            >
                {{ $t("buttons.add") }}
                <v-icon right class="pr-1">
                    mdi-account-plus
                </v-icon>
            </v-btn>
        </div>

        <!-- continue btn -->
        <v-row justify="center" class="mt-6" v-show="showFields">
            <v-tooltip bottom :disabled="readyForInterval" color="transparent">
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <base-btn
                            @click="next"
                            :disabled="!readyForInterval"
                            :label="
                                members.length > 0
                                    ? $t('buttons.saveContinue')
                                    : $t('team.continueWithout')
                            "
                            class="mt-12"
                        />
                    </div>
                </template>
                <v-card dark color="grey darken-1">
                    <v-card-text>
                        {{ $t("team.fillSteps") }}
                    </v-card-text>
                </v-card>
            </v-tooltip>
            <div
                v-show="members.length < 1"
                class="text-center text-caption subtext mt-2"
            >
                {{ $t("team.canAdd") }}
            </div>
        </v-row>

        <v-alert
            v-if="lessthan4members"
            outlined
            text
            color="info"
            class="my-10"
            icon="mdi-information"
        >
            <p>{{ $t("team.minMemberInfo") }}</p>

            <i18n path="team.minMemberContact">
                <a
                    class="linkText"
                    target="_blank"
                    :href="contactLink"
                >
                    {{ $t("questions.here") }}
                </a>
            </i18n>
        </v-alert>
    </v-stepper-content>
</template>

<script>
export default {
    name: "",
    props: ["readyForInterval"],
    data: () => ({
        firstName: "",
        lastName: "",
        email: "",
        isFormValid: false,
        showFields: false,
        notEmpty: [v => (v && !!v.trim()) || ""],
        emailRules: [
            v => !!v,
            v => /.+@.+\..+/.test(v) || "",
            v => (v || "").indexOf(" ") < 0 || ""
        ]
    }),
    methods: {
        saveMember() {
            this.$store.commit("addMember", {
                firstName: this.firstName.trim(),
                lastName: this.lastName.trim(),
                email: this.email.trim().toLowerCase()
            });
            this.resetFields();
            this.resetForm();
        },
        removeMember(i) {
            this.$store.commit("removeMember", i);
        },
        resetFields() {
            (this.firstName = ""), (this.lastName = ""), (this.email = "");
        },
        resetForm() {
            this.$refs.form.reset();
        },
        next() {
            this.$emit("next");
        }
    },
    computed: {
        members() {
            return this.$store.state.newUser.teamMembers;
        },
        lessthan4members() {
            return this.$store.state.newUser.teamMembers.length < 4;
        },
        existingEmails() {
            return this.$store.getters.allExistingEmails;
        },
        emailExists() {
            // checks if currently entered e-mail is already in use
            return this.email && this.existingEmails.includes(this.email.toLowerCase());
        },
             contactLink(){
            return this.$store.getters.contactLink
        }
    }
};
</script>

<style>
.border-right {
    border-right: 1px solid lightgrey;
}
.linkText {
    font-weight: bold;
    text-decoration: none;
    color: #7383b9 !important;
}
</style>
