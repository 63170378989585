import { render, staticRenderFns } from "./LanguageSet.vue?vue&type=template&id=91880be4&scoped=true"
import script from "./LanguageSet.vue?vue&type=script&lang=js"
export * from "./LanguageSet.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91880be4",
  null
  
)

export default component.exports