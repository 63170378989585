import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import BaseBtn from "./components/global/BaseBtn.vue";
import Flag from "./components/global/Flag.vue";
import AnalyticsService from "./services/analytics.service"
import globalFunctions from './utils/globalFunctions';

require('@/styles/main.css')

Vue.use(require('vue-moment'));
Vue.component('base-btn', BaseBtn)
Vue.component('Flag', Flag)

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')


AnalyticsService.init();
