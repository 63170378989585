<template>
    <v-container>
        <v-row justify="center" no-gutters class="fade-in">
            <v-col
                cols="12"
                md="8"
                lg="6"
                xl="5"
                v-if="registerResponse"
                class="text-center"
            >
                <h3 class="slide-in mt-4 mb-7 ">
                    {{ $t("success.welcome") }}, {{ user.firstName }}!
                </h3>

                <v-img
                    class="slide-in"
                    :src="require(`@/assets/images/group.png`)"
                    :max-height="$vuetify.breakpoint.xs ? 90 : 180"
                    contain
                ></v-img>

                <div class="mt-12">
                    <h3 class="mb-2">{{ $t("success.whatNext") }}</h3>

                    <i18n path="success.surveyStarts">
                        <span class="font-weight-bold">{{ registerResponse.surveyStart | moment("DD-MM-YYYY, kk:mm") }}</span>
                    </i18n>

                    <!-- startNow content -->
                    <div v-if="user.startNow">
                        <p class="mb-0 mt-2">
                            <i18n path="success.useLink">
                                <span class="text-lowercase">
                                    {{
                                        user.teamMembers.length > 0
                                            ? $t("success.more")
                                            : ""
                                    }}
                                </span>
                            </i18n>
                        </p>
                    </div>

                    <!-- start later content -->
                    <div v-else>
                        <p class="mt-2 mb-0 pb-0">
                            <i18n path="success.useLinkMeanwhile">
                                <span class="text-lowercase">
                                    {{
                                        user.teamMembers.length > 0
                                            ? $t("success.more")
                                            : ""
                                    }}
                                </span>
                            </i18n>
                        </p>
                    </div>

                    <!-- link -->
                    <div class="mt-8 mb-3">
                        <v-card flat color="grey lighten-3" class="pa-2 pos-relative">

                                <a
                                    style="word-break: break-all"
                                    target="_blank"
                                    :href="registerUrl"
                                >
                                    {{ registerUrl }}
                                </a>

                                <v-btn
                                    x-small
                                    icon
                                    @click="onCopy"
                                    class="pos-relative"
                                >
                                    <v-icon small color="grey">
                                        mdi-content-copy
                                    </v-icon>
                                </v-btn>

                                <v-scale-transition>
                                    <v-btn
                                        v-show="copyMessage"
                                        dark
                                        color="blue lighten-1"
                                        class="copyMessage"
                                    >
                                        {{ $t("team.linkCopied") }}
                                    </v-btn>
                                </v-scale-transition>

                        </v-card>
                    </div>
                    <!-- /link -->

                    <div v-if="!user.startNow" class="mt-7">
                        {{ $t("success.dashboard") }}
                    </div>

                    <base-btn
                        :href="dashboardUrl"
                        large
                        :label="$t('success.go')"
                        class="my-8 mx-auto"
                    />
                </div>
            </v-col>

            <!-- when landing here with no prior signup -->
            <div v-else class="text-center mt-10">
                No account data
                <br />

                <v-img
                    class="mx-auto pop"
                    :src="require(`@/assets/images/search.png`)"
                    max-width="150"
                    contain
                ></v-img>
            </div>

            <!-- hidden input for copying link -->
            <input
                style="opacity: 0;position: absolute"
                ref="linkField"
                id="teamLink"
                type="text"
                :value="registerUrl"
            />
        </v-row>
    </v-container>
</template>

<script>
import Configuration from "../services/configuration";
export default {
    name: "",
    props: [""],
    components: {},
    data: () => ({
        intervalOptions: [
            // { text: "interval.weekly", value: "WEEKLY" },
            // { text: "interval.every2w", value: "BIMONTHLY" },
            // { text: "interval.every3w", value: "TRIMONTHLY" },
            // { text: "interval.monthly", value: "MONTHLY" },
            { text: "interval.every6w", value: "BIQUARTERLY" },
            { text: "interval.every2m", value: "EVERY2MONTHS" },
            { text: "interval.every3m", value: "QUARTERLY" },
            { text: "interval.every4m", value: "EVERY4MONTHS" },
        ],
        copyMessage: false,
    }),
    methods: {
        intervalText(interval) {
            return this.intervalOptions.filter(
                (item) => item.value === interval
            )[0].text;
        },

        onCopy() {
            document.getElementById("teamLink").select();
            document.execCommand("copy");
            document.getSelection().removeAllRanges();
            this.$refs.linkField.blur();

            this.copyMessage = true;
            setTimeout(() => {
                this.copyMessage = false;
            }, 1000);
        },
    },
    computed: {
        user() {
            return this.$store.state.newUser;
        },
        registerResponse() {
            return this.$store.state.registerResponse;
        },
        registerUrl() {
            return (
                Configuration.value("registerUrl") +
                "/join?token=" +
                this.registerResponse.teamToken
            );
        },
        dashboardUrl() {
            return (
                this.registerResponse.loginLink
            );
        },
    },
};
</script>

<style >
.copyMessage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
