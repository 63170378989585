// import axios from "axios";

const state = () => ({
    selectedSetId: "634e6744625b99bedb444e53", //Job satisfaction by default
    selectedQuestions: [],
    categories: null,
    definedSets: null,
    avatars: [
        "balance",
        "book",
        "cloud",
        "coffee",
        "crab",
        "cup",
        "donut",
        "fridge",
        "hammer",
        "ice",
        "ice_cream",
        "kitty",
        "ladder",
        "laptop",
        "mobile",
        "mouse",
        "mouth",
        "pen",
        "plant",
        "screwdriver",
        "sun",
        "superman",
        "target",
        "toast",
        "tree",
    ],
    defaultAvatars: ["sun", "kitty", "target", "ice", "coffee", "balance", "ladder", "crab", "plant", "fridge", "mouse", "book"]
})

const mutations = {
    ADD_QUESTION(state, question) {
        // will receive a whole object
        state.selectedQuestions.push(question)
    },
    DELETE_QUESTION(state, question) {
        state.selectedQuestions = state.selectedQuestions.filter(item => item !== question)
    },
    RESET_LIST(state) {
        state.selectedQuestions = [];
    },
    SET_LIST(state, list) {
        state.selectedQuestions = list;
    },
    SET_LIST_ID(state, id) {
        state.selectedSetId = id;
    },
    SET_AVATAR(state, [avatar, i]) {
        state.selectedQuestions[i].icon = avatar;
    },
    SET_CATEGORIES(state, categories) {
        state.categories = categories.sort((a, b) => (Object.prototype.hasOwnProperty.call(a, 'defaultSet') ? 1 : -1))

        let definedSets = categories.filter(category => Object.prototype.hasOwnProperty.call(category, 'defaultSet'))
        if(definedSets.length>0){
            state.definedSets = definedSets
        }

    },
}

const actions = {
    addQuestion(context, question) {
        context.commit("ADD_QUESTION", question)
    },
    deleteQuestion(context, question) {
        context.commit("DELETE_QUESTION", question)
    },
    resetList(context) {
        context.commit("RESET_LIST")
    },
    setList(context, list) {
        context.commit("SET_LIST", list)
    },
    setAvatar(context, [avatar, i]) {
        context.commit("SET_AVATAR", [avatar, i])
    },
    setCategories(context, categories) {
        context.commit("SET_CATEGORIES", categories)
    },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

