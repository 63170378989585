<template>
    <v-img
       v-bind="$attrs"
        style="display: inline-block"
        :src="require(`@/assets/images/flags/${locale}.png`)"
        width="20"
        contain
    ></v-img>
</template>

<script>
export default {
    props: ["locale"],
    computed:{

    }
};
</script>
