var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-expansion-panels',{attrs:{"multiple":"","focusable":"","hover":""}},_vm._l((_vm.categories),function(category){return _c('v-expansion-panel',{key:category.id},[_c('v-expansion-panel-header',{staticClass:"pl-2",class:{
                    defaultSet: Object.prototype.hasOwnProperty.call(
                        category,
                        'defaultSet'
                    )
                }},[_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{attrs:{"size":"50"}},[_c('img',{attrs:{"src":category.icon,"alt":"category icon"}})]),_c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"font-weight-bold text-uppercase main-category"},[_vm._v(" "+_vm._s(_vm.translatedText(category.name, _vm.$i18n.locale))+" ")]),(
                                Object.prototype.hasOwnProperty.call(
                                    category,
                                    'description'
                                )
                            )?_c('div',{staticClass:"mt-1 sub-description grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.translatedText( category.description, _vm.$i18n.locale ))+" ")]):_vm._e()])],1)]),_c('v-expansion-panel-content',{attrs:{"eager":""}},[(category.subCategories)?_c('v-list',[_c('SubCategories',{attrs:{"categoryId":category.id}})],1):_vm._e(),(
                        category.hasOwnProperty('questions') &&
                            category.questions.length > 0
                    )?_c('v-list',[_c('QuestionCollection',{attrs:{"subId":category.id}})],1):_vm._e()],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }