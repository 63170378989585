var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('v-card',{staticClass:"fade-in",attrs:{"color":"#f5f5f5"}},[_c('v-card-text',{staticClass:"pt-0"},[_c('v-row',{attrs:{"no-gutters":"","justify":"start"}},[_c('v-col',{staticClass:"mt-5"},[_c('span',{staticClass:"d-flex align-center"},[_c('v-img',{attrs:{"src":require(`@/assets/images/avatars/book/5.png`),"max-height":"30","max-width":"25","contain":""}}),_vm._v(" "+_vm._s(_vm.$t("questions.picture")))],1),_c('v-select',{attrs:{"hide-details":"","solo":"","flat":"","items":_vm.avatars},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"mr-1",staticStyle:{"max-width":"30px"}},[_c('v-img',{attrs:{"contain":"","src":require(`@/assets/images/avatars/${data.item}/5.png`)}})],1),_c('v-col',[_vm._v(_vm._s(data.item))])],1)]}}]),model:{value:(_vm.questionAvatar),callback:function ($$v) {_vm.questionAvatar=$$v},expression:"questionAvatar"}})],1),_c('v-col',[_c('div',{staticClass:"py-0 pl-3 d-flex align-center hover-pointer",on:{"click":function($event){_vm.showDemo = true}}},[_c('v-img',{key:_vm.questionAvatar,staticClass:"avatar",attrs:{"src":require(`@/assets/images/avatars/${_vm.questionAvatar}/5.png`),"max-height":"100","max-width":"100","contain":""}}),_c('div',{staticClass:"demoLink"},[_c('v-icon',[_vm._v(" mdi-cursor-default-click-outline ")]),_vm._v(" demo ")],1)],1)])],1),(_vm.singleLanguage)?_c('div',{staticClass:"mb-3"},[_c('v-form',{model:{value:(_vm.isFilled),callback:function ($$v) {_vm.isFilled=$$v},expression:"isFilled"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('Flag',{attrs:{"locale":_vm.surveyLang}}),_vm._v(" "+_vm._s(_vm.$t("questions.title"))+" "),_c('v-text-field',{staticClass:"questionTitle",attrs:{"rules":_vm.notEmpty,"hide-details":"","solo":"","flat":"","label":_vm.$t('questions.title'),"placeholder":_vm.$t('questions.example') +
                                        _vm.$t('questions.exampleT')},model:{value:(_vm.question.title),callback:function ($$v) {_vm.$set(_vm.question, "title", $$v)},expression:"question.title"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('Flag',{attrs:{"locale":_vm.surveyLang}}),_vm._v(" "+_vm._s(_vm.$t("questions.question"))+" "),_c('v-text-field',{attrs:{"rules":_vm.notEmpty,"hide-details":"","solo":"","flat":"","label":_vm.$t('questions.question'),"placeholder":_vm.$t('questions.example') +
                                        _vm.$t('questions.exampleQ')},model:{value:(_vm.question.questionText),callback:function ($$v) {_vm.$set(_vm.question, "questionText", $$v)},expression:"question.questionText"}})],1)],1)],1)],1):_c('div',{staticClass:"mb-4"},[_c('v-form',{model:{value:(_vm.isMultiFilled),callback:function ($$v) {_vm.isMultiFilled=$$v},expression:"isMultiFilled"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('Flag',{attrs:{"locale":'de'}}),_vm._v(" "+_vm._s(_vm.$t("questions.title"))+" "),_c('v-text-field',{staticClass:"questionTitle",attrs:{"rules":_vm.notEmpty,"hide-details":"","solo":"","flat":"","label":_vm.$t('questions.title'),"placeholder":_vm.$t('questions.example') +
                                        _vm.$t('questions.exampleT')},model:{value:(_vm.multiQuestion.title.de),callback:function ($$v) {_vm.$set(_vm.multiQuestion.title, "de", $$v)},expression:"multiQuestion.title.de"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('Flag',{attrs:{"locale":'de'}}),_vm._v(" "+_vm._s(_vm.$t("questions.question"))+" "),_c('v-text-field',{attrs:{"rules":_vm.notEmpty,"hide-details":"","solo":"","flat":"","label":_vm.$t('questions.question'),"placeholder":_vm.$t('questions.example') +
                                        _vm.$t('questions.exampleQ')},model:{value:(_vm.multiQuestion.questionText.de),callback:function ($$v) {_vm.$set(_vm.multiQuestion.questionText, "de", $$v)},expression:"multiQuestion.questionText.de"}})],1)],1),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('Flag',{attrs:{"locale":'en'}}),_vm._v(" "+_vm._s(_vm.$t("questions.title"))+" "),_c('v-text-field',{staticClass:"questionTitle",attrs:{"rules":_vm.notEmpty,"hide-details":"","solo":"","flat":"","label":_vm.$t('questions.title'),"placeholder":_vm.$t('questions.example') +
                                        _vm.$t('questions.exampleT')},model:{value:(_vm.multiQuestion.title.en),callback:function ($$v) {_vm.$set(_vm.multiQuestion.title, "en", $$v)},expression:"multiQuestion.title.en"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('Flag',{attrs:{"locale":'en'}}),_vm._v(" "+_vm._s(_vm.$t("questions.question"))+" "),_c('v-text-field',{attrs:{"rules":_vm.notEmpty,"hide-details":"","solo":"","flat":"","label":_vm.$t('questions.question'),"placeholder":_vm.$t('questions.example') +
                                        _vm.$t('questions.exampleQ')},model:{value:(_vm.multiQuestion.questionText.en),callback:function ($$v) {_vm.$set(_vm.multiQuestion.questionText, "en", $$v)},expression:"multiQuestion.questionText.en"}})],1)],1)],1)],1),_c('v-row',{staticClass:"mx-1",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","disabled":!_vm.isFormValid},on:{"click":_vm.saveQuestion}},[_vm._v(" "+_vm._s(_vm.$t("buttons.saveQuestion"))+" ")])],1)],1)],1),_c('AvatarDemo',{attrs:{"questionText":_vm.question.questionText,"showDemo":_vm.showDemo,"questionAvatar":_vm.questionAvatar},on:{"demoStatus":function($event){_vm.showDemo = $event},"avatarChanged":function($event){_vm.questionAvatar = $event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }