<template>
    <div class="fade-in">
        <div align="center">
            <p class="subheading">
                {{ $t("questions.introText") }}
            </p>
            <p class="mb-12">
                {{ $t("questions.questionsSelect") }}
                <span
                    v-show="$vuetify.breakpoint.smAndDown"
                    class="text--secondary"
                >
                    ({{ $t("questions.desktop") }}
                    <v-icon small class="secondary--text">
                        mdi-monitor
                    </v-icon>
                    )
                </span>
            </p>
        </div>



        <!-- desktop view -->
        <v-row v-show="$vuetify.breakpoint.mdAndUp" justify="center">
            <v-col style="max-width: 700px" cols="7">
                <v-row no-gutters justify="center">
                    <v-col
                        cols="3"
                        v-for="category in definedSets"
                        :key="category.id"
                        style="max-width: 200px"
                    >
                        <div
                            @click="selectTile(category.id)"
                            class="tileCard transitioned pa-1"
                        >
                            <v-card-text class="text-center pa-0">
                                <v-img
                                    :src="category.icon"
                                    style="z-index:2; position: relative;"
                                    max-width="120"
                                    contain
                                    class="setPic d-flex mx-auto"
                                >
                                </v-img>

                                <div
                                    class="setName"
                                    :class="{
                                        selectedName: chosenSet == category.id
                                    }"
                                >
                                    {{
                                       translatedText(category.name, $i18n.locale)
                                    }}
                                </div>
                                <div
                                    v-if="chosenSet == category.id"
                                    class="selected-underline fade-in"
                                ></div>
                                <div v-else class="transparent-underline"></div>
                            </v-card-text>
                        </div>
                    </v-col>

                    <!-- custom set -->
                    <v-col cols="3" style="max-width: 150px">
                        <div
                            @click="selectCustom"
                            class="tileCard transitioned pa-1"
                        >
                            <v-card-text class="text-center pa-0">
                                <v-img
                                    :src="
                                        require('@/assets/images/catalog-round.png')
                                    "
                                    max-width="120"
                                    contain
                                    class="setPic d-flex mx-auto"
                                    style="z-index: 2"
                                ></v-img>

                                <div
                                    class="setName"
                                    :class="{
                                        selectedName: customSet
                                    }"
                                >
                                    {{ $t("questions.customSet") }}
                                </div>
                                <div
                                    v-if="customSet"
                                    class="selected-underline fade-in"
                                ></div>
                                <div v-else class="transparent-underline"></div>
                            </v-card-text>
                        </div>
                    </v-col>
                    <!-- /custom set -->
                </v-row>
            </v-col>

            <v-col style="max-width: 250px; flex-basis: 250px">
                <v-card
                    width="250"
                    min-height="220"
                    flat
                    color="#E9E6E6"
                    class="rounded-xl mt-5"
                >
                    <v-card-text
                        class="slide-in-left-small pt-6 text-center"
                        :key="setDescription"
                    >
                        <div
                            class="font-weight-bold text-uppercase text-body-1"
                        >
                            {{ setName }}
                        </div>
                        <div class="setDescription mt-1">
                            {{
                                customSet
                                    ? $t("questions.customDescription")
                                    : setDescription
                            }}
                        </div>
                    </v-card-text>

                    <v-card-actions>
                        <base-btn
                            @click="proceed"
                            :label="$t('buttons.select')"
                            class="my-2 d-flex mx-auto"
                        />
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <!-- desktop view -->

        <!-- mobile view -->
        <div v-show="$vuetify.breakpoint.smAndDown">
            <v-row v-for="category in definedSets" :key="`${category.id}-set7`">
                <v-card flat class="mx-auto mb-8" color="transparent">
                    <v-card-text class="text-center">
                        <div class="setName">
                           {{ translatedText(category.name, $i18n.locale) }}
                        </div>
                        <v-img
                            class="mx-auto mb-1"
                            :src="category.icon"
                            contain
                            max-width="100"
                        ></v-img>
                        <div class="" style="max-width:400px">
                            <p class="setDescription" v-if="Object.prototype.hasOwnProperty.call(category, 'description')">
                                {{ translatedText(category.description, $i18n.locale) }}
                            </p>
                        </div>
                        <v-btn
                            depressed
                            dark
                            class="primary"
                            @click="mobileProceed(category.id)"
                        >
                            <span>{{ $t("buttons.select") }}</span>
                        </v-btn>
                    </v-card-text>
                    <div class="separator"></div>
                </v-card>
            </v-row>

            <v-row>
                <!-- disabled custom set -->
                <v-card flat class="mx-auto mt-6" color="transparent">
                    <v-card-text class="text-center">
                        <div class="setName secondary--text">
                            {{ $t("questions.customSet") }}
                        </div>
                        <v-img
                            class="mx-auto"
                            style="opacity:60%"
                            :src="require('@/assets/images/catalog-round.png')"
                            contain
                            max-width="100"
                        ></v-img>

                        <p class="setDescription">
                            <i18n path="questions.goDesktop">
                                <v-icon class="secondary--text">
                                    mdi-monitor
                                </v-icon>
                                >
                            </i18n>
                        </p>

                        <v-btn depressed disabled>
                            {{ $t("buttons.select") }}
                        </v-btn>
                    </v-card-text>
                </v-card>
                <!-- disabled custom set -->
            </v-row>
        </div>
        <!-- /mobile view -->



    </div>
</template>

<script>
export default {
    name: "Questions",
    props: [""],
    components: {},
    data: () => ({
        chosenSet: "634e6744625b99bedb444e53", //hardcoded Job satisfaction id
        customSet: false //tracks if user has selected "custom set" tile
    }),
    methods: {
        nextStep() {
            this.$emit("nextStep");
            this.$router.push({ name: "team" });
        },
        selectTile(id) {
            this.customSet = false;
            this.chosenSet = id;
        },
        selectCustom() {
            this.customSet = true;
            this.chosenSet = null;
        },
        proceed() {
            if (this.chosenSet) {
                // see premade set
                this.$store.commit(
                    "questionpicker/SET_LIST_ID",
                    this.chosenSet
                );
                this.$router.push({ name: "questions-preview" });
            } else {
                // empty list and go build
                this.$store.dispatch("questionpicker/resetList");
                this.$router.push({ name: "questions-build" });
            }
        },
        mobileProceed(id) {
            this.chosenSet = id;
            this.$store.commit("questionpicker/SET_LIST_ID", this.chosenSet);
            this.$router.push({ name: "questions-preview" });
        }
    },
    computed: {
       setDescription() {
            if (this.chosenSet && this.definedSets) {
                let foundCategory = this.definedSets.find(item => item.id == this.chosenSet)

                if(foundCategory && Object.prototype.hasOwnProperty.call(foundCategory, 'description')){
                    return this.translatedText(foundCategory.description, this.$i18n.locale)
                }else{
                    return "DESCRIPTION MISSING"
                }


            } else {
                return "";
            }
        },
          setName() {
            if (this.chosenSet && this.definedSets) {
                let foundCategory = this.definedSets.find(item => item.id == this.chosenSet)

                if(foundCategory && Object.prototype.hasOwnProperty.call(foundCategory, 'name')){
                    return this.translatedText(foundCategory.name, this.$i18n.locale)
                }else{
                    return ""
                }


            } else {
                return this.$t("questions.customSet")
            }
        },
        definedSets(){
            return this.$store.state.questionpicker.definedSets
        }
    }
};
</script>

<style>
.tileCard:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.tileCard:hover .setPic {
    animation: pulse-small 2s infinite linear;
}

.tileCard:active {
    animation: select 0.2s;
}

.setName {
    font-size: 1rem;
    font-weight: 600;
    padding: 5px 10px;
}

.selectedName {
    font-weight: 800;
}

.selected-underline {
    height: 4px;
    background-color: #a03058;
    width: 90%;
    border-radius: 2px;
    margin: 0 auto;
    transition: all 0.1s linear;
}
.transparent-underline {
    height: 4px;
    background-color: transparent;
}

.setDescription {
    font-size: 0.95rem;
}
</style>
