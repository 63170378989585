<template>
<!-- in the question picker -->
  <div>

        <v-list-item
            dense
            class="py-0 pl-1"
            :class="{ usedQuestion: isSelected }"
            @click="selectItem"
            link
            :ripple="false"
            style="position: relative"
        >
            <v-list-item-content class="py-0">
                <v-row align="center" no-gutters>
                    <v-col style="max-width:12px" class="py-0 pl-1">
                       <v-icon small>
                            {{ isSelected ? "mdi-check" : "mdi-circle-small" }}
                        </v-icon>
                    </v-col>
                    <v-col class="py-0">
                        <v-card-text class="py-1 pr-0">
                             {{ translatedText(question.questionText, $i18n.locale)}}
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-list-item-content>
        </v-list-item>

        <v-snackbar
            color="grey darken-2"
            timeout="3000"
            v-model="showWarning"
            centered
        >
            <v-row align="center">
                <v-col style="max-width:150px">
                    <v-img
                        :src="require(`@/assets/images/avatars/pen/1.png`)"
                        contain
                        max-width="150"
                    ></v-img>
                </v-col>
                <v-col class="text-center" style="font-size: 1.1rem">
                    <div class="mb-1">{{ $t("questions.setFull") }}</div>

                    <div>{{ $t("questions.pleaseRemove") }}</div>
                </v-col>
                <v-col
                    style="max-width:30px"
                    class="mr-3 mt-n3"
                    align-self="start"
                >
                    <v-btn icon @click="showWarning = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-snackbar>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "",
    props: ["question"],
    components: {},
    data: () => ({
        showWarning: false,
    }),
    methods: {
        selectItem() {
            if (this.setFull && !this.isSelected) {
                this.showWarning = true;
            }
            // add to basket if not selected and not yet 12 questions
            if (!this.setFull && !this.isSelected) {
                let newQ = this.question;
                newQ.icon = this.uniqueIcon();
                this.$store.dispatch("questionpicker/addQuestion", newQ);
                // delete if already selected
            } else {
                let question = this.selectedQuestions.find(
                    (item) => item.id === this.question.id
                );
                this.$store.dispatch("questionpicker/deleteQuestion", question);
            }
            // revert styling to reflect selection state
            this.isSelected = !this.isSelected;
        },
        uniqueIcon() {
            let icon = this.avatars[Math.floor(Math.random() * 25)];
            let existingIcons = this.selectedQuestions.map((el) => el.icon);

            if (!existingIcons.includes(icon)) {
                return icon;
            } else {
                return this.uniqueIcon();
            }
        },
    },
    computed: {
        ...mapState("questionpicker", ["selectedQuestions", "avatars"]),
        setFull() {
            return this.selectedQuestions.length >= 12;
        },

        isSelected: {
            get() {
                // true if questionpicker question id exists in basket
                 return this.selectedQuestions.find(
                    (item) => item.id === this.question.id
                );
            },
            set(newValue) {
                return newValue;
            },
        },
        // existingIcons() {
        //     return this.selectedQuestions.map((el) => el.icon);
        // },
    },
};
</script>

<style>
.usedQuestion {
    background-color: rgb(242, 250, 247);
    border-radius: 3px;
    margin: 2px 0px;
    border: 1px solid rgb(154, 209, 154);
}
</style>
