<template>
    <!-- shown when user selects a set. this is a preview of the question set -->
    <v-card class="mt-10 pb-3 mx-auto" color="#F7F2F2" max-width="700">
        <v-btn
            outlined
            color="grey"
            @click="$router.push({ name: 'start' })"
            style="position: absolute; top: -43px"
        >
            {{ $t("buttons.backToSets") }}
        </v-btn>
        <v-container v-if="selectedSetId && categories">
            <p class="text-center font-weight-bold">
                <i18n path="questions.reviewSet">
                    <span>
                        "{{
                            setName[$i18n.locale]
                                ? setName[$i18n.locale]
                                : setName.en
                        }}"
                    </span>
                </i18n>
            </p>

            <v-alert
                dismissible
                v-model="infoBox"
                dense
                outlined
                text
                color="info"
            >
                <i18n
                    path="questions.dragDrop"
                    v-if="$route.name === 'questions-preview'"
                >
                    <v-icon small>mdi-drag-variant</v-icon>
                    >
                </i18n>
                <i18n path="questions.dragReview" v-else>
                    <v-icon small>mdi-drag-variant</v-icon>
                    >
                </i18n>

                <i18n path="questions.clickPicture" class="ml-1">
                    <span>
                        <v-img
                            :src="
                                require(`@/assets/images/avatars/target/5.png`)
                            "
                            max-height="24px"
                            max-width="24px"
                            contain
                            style="display: inline-block; margin-bottom: -5px"
                        ></v-img>
                    </span>
                    >
                </i18n>
            </v-alert>

            <draggable
                class="list-group"
                v-model="questionSet"
                v-bind="dragOptions"
                @start="drag = true"
                @end="drag = false"
            >
                <transition-group name="list-complete" tag="div">
                    <SelectedQuestion
                        class="list-complete-item"
                        v-for="(question, i) in questionSet"
                        :key="question.questionText[$i18n.locale]"
                        :question="question"
                        :i="i"
                        :mode="'review'"
                        @avatarChanged="changeAvatar($event)"
                    />
                </transition-group>
            </draggable>
        </v-container>

        <div class="text-center">
            <span v-show="$vuetify.breakpoint.mdAndUp">
                <v-btn
                    large
                    @click="goEditQuestions"
                    color="primary"
                    text
                    :ripple="false"
                >
                    <v-icon x-small class="mr-1">
                        mdi-pencil
                    </v-icon>
                    <span>{{ $t("questions.editQuestions") }}</span>
                </v-btn>

                {{ $t("questions.or") }}
            </span>
            <base-btn
                large
                @click="saveQuestions"
                :label="$t('questions.continueSet')"
                class="ml-4"
            />
        </div>
    </v-card>
</template>

<script>
import { mapState } from "vuex";
import { getCategorySet } from "@/api/catalog.api.js";
import draggable from "vuedraggable";
import SelectedQuestion from "@/components/questionpicker/SelectedQuestion.vue";
export default {
    name: "",
    props: [],
    components: { draggable, SelectedQuestion },
    data: () => ({
        drag: false,
        infoBox: true,
        questionSet: null, //copy of categorySet from API
    }),
    created() {
        getCategorySet(this.selectedSetId).then(
            (response) => {
                // assign unique icons
                let set = response.data;
                for (let i = 0; i < response.data.length; i++) {
                    set[i].icon = this.defaultAvatars[i];
                }
                this.questionSet = set;
            },
            (error) => {
                console.log(error);
            }
        );
    },
    computed: {
        ...mapState("questionpicker", [
            "selectedSetId",
            "categories",
            "defaultAvatars",
        ]),
        setName() {
            if (this.selectedSetId) {
                return this.categories.find(
                    (item) => item.id == this.selectedSetId
                ).name;
            } else {
                return "";
            }
        },
        dragOptions() {
            return {
                animation: 200,
                disabled: false,
                group: "description",
                ghostClass: "ghost",
            };
        },
    },
    methods: {
        goEditQuestions() {
            this.$store.dispatch("questionpicker/setList", this.questionSet);
            this.$router.push({ name: "questions-build" });
        },
        saveQuestions() {
            this.$store.dispatch("questionpicker/setList", this.questionSet);
            this.$store.commit("setStep", [0, true]);
            this.$router.push({ name: "team" });
        },

        changeAvatar([avatar, i]) {
            this.questionSet[i].icon = avatar;
        },
    },
};
</script>

<style></style>
