<template>
    <div>
        <v-expansion-panels multiple focusable hover>
            <v-expansion-panel
                v-for="category in categories"
                :key="category.id"
            >
                <v-expansion-panel-header
                    class="pl-2"
                    :class="{
                        defaultSet: Object.prototype.hasOwnProperty.call(
                            category,
                            'defaultSet'
                        )
                    }"
                >
                    <div class="d-flex align-center">
                        <v-avatar size="50">
                            <img :src="category.icon" alt="category icon" />
                        </v-avatar>
                        <div class="ml-2">
                            <div
                                class="font-weight-bold text-uppercase main-category"
                            >
                                {{
                                    translatedText(category.name, $i18n.locale)
                                }}
                            </div>

                            <div
                                v-if="
                                    Object.prototype.hasOwnProperty.call(
                                        category,
                                        'description'
                                    )
                                "
                                class="mt-1 sub-description grey--text text--darken-2"
                            >
                                {{
                                    translatedText(
                                        category.description,
                                        $i18n.locale
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                    <!-- show subs  -->
                    <v-list v-if="category.subCategories">
                        <SubCategories :categoryId="category.id" />
                    </v-list>
                    <!-- /show subs  -->

                    <!-- just show items if no subcategory -->
                    <v-list
                        v-if="
                            category.hasOwnProperty('questions') &&
                                category.questions.length > 0
                        "
                    >
                        <QuestionCollection :subId="category.id" />
                    </v-list>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import SubCategories from "@/components/questionpicker/SubCategories.vue";
import QuestionCollection from "@/components/questionpicker/QuestionCollection.vue";
export default {
    name: "",
    props: [""],
    components: { SubCategories, QuestionCollection },
    data: () => ({
        showWarning: false,
        icons: [1, 4, 2, 3, 6, 7, 5]
    }),
    methods: {},
    computed: {
        categories() {
            return this.$store.state.questionpicker.categories;
        }
    }
};
</script>

<style>
.main-category {
    font-size: 1.2rem;
}

.sub-description {
    font-size: 0.9rem;
    color: grey;
    font-weight: 400;
}

.v-expansion-panel-header:hover,
.v-expansion-panel-header::before,
.v-expansion-panel-header::after {
    background-color: rgb(248, 243, 243);
}
.v-expansion-panel-header--active {
    background-color: rgba(248, 243, 243, 0.918);
}

.defaultSet {
    background-color: rgb(255, 250, 221);
}

.defaultSet:hover {
    background-color: rgb(252, 244, 195);
}

.v-list-item__title {
    user-select: text;
    text-overflow: unset !important;
    white-space: normal;
    font-family: Lato, sans-serif;
    color: rgb(51, 51, 51);
}

.v-list-item--active {
    border-bottom: 1px solid rgb(235, 235, 235);
}
</style>
