<template>
    <div>
        <!-- lang set change for survey  -->
        <div class="pa-3">
            {{ $t("questions.surveyLang") }}:

            <FlagDisplay :surveyLang="surveyLang" />

            <v-btn
                @click="dialog = true"
                small
                text
                class="btn-text mt-n1 ml-1 px-0"
                color="primary"
            >
                {{ $t("questions.change") }}
            </v-btn>
        </div>
        <v-divider></v-divider>
        <!-- lang set change for survey  -->

        <v-dialog v-model="dialog" max-width="500">
            <v-card>
                <v-card-text class="pt-8">
                    <p class="font-weight-bold">
                        {{ $t("questions.changeLangs") }}
                    </p>
                    {{ $t("questions.changeExplanation") }}

                    <v-select
                        style="max-width: 220px"
                        hide-details
                        solo
                        flat
                        v-model="currentLang"
                        :items="languages"
                    >
                        <template v-slot:item="data">
                            <FlagDisplay :surveyLang="data.item" />
                        </template>

                        <template v-slot:selection="data">
                            <FlagDisplay :surveyLang="data.item" />
                        </template>
                        <template v-slot:append-item>
                            <v-divider></v-divider>
                            <div class="pt-2 px-2 text-caption text-center subtext">
                                <i18n path="questions.getCustom">
                                    <v-img
                                        style="display: inline-block"
                                        :src="
                                            require(`@/assets/images/flags/fr.png`)
                                        "
                                        width="12"
                                        contain
                                    ></v-img>
                                    <v-img
                                        style="display: inline-block"
                                        :src="
                                            require(`@/assets/images/flags/it.png`)
                                        "
                                        width="12"
                                        contain
                                    ></v-img>
                                    <a
                                        class="text-decoration-none"
                                        target="_blank"
                                        :href="contactLink"
                                    >
                                        {{ $t("questions.here") }}
                                    </a>
                                </i18n>
                            </div>
                        </template>
                    </v-select>
                </v-card-text>

                <div v-if="existingQuestions > 0" class="px-3">
                    <v-alert outlined type="info" text>
                        <span v-if="existingQuestions === 1">
                            {{ $t("questions.delete1") }}
                        </span>
                        <i18n v-else path="questions.deleteMore">
                            {{ existingQuestions }}
                        </i18n>
                    </v-alert>
                </div>

                <v-card-actions>
                    <v-btn text @click="dialog = false">
                        {{ $t("buttons.cancel") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <base-btn @click="save" :label="'OK'" class="mx-auto" />
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import FlagDisplay from "@/components/FlagDisplay.vue";
export default {
    name: "",
    props: [""],
    components: { FlagDisplay },
    created() {
        // set lang to locale if not yet modified
        if (!this.surveyLang) {
            let lang = this.languages.filter(
                (lang) => lang === this.$i18n.locale
            )[0];
            this.$store.commit("setSurveyLanguage", lang);
        }
        this.currentLang = this.surveyLang;
    },
    data: () => ({
        dialog: false,
        currentLang: null,
        languages: ["de", "en", ["de", "en"]],
    }),
    methods: {
        save() {
            // delete existing questions
            if (this.existingQuestions > 0) {
                this.$store.dispatch("questionpicker/resetList");
            }

            this.$store.commit("setSurveyLanguage", this.currentLang);
            this.dialog = false;
        },
    },
    computed: {
        surveyLang() {
            return this.$store.state.surveyLanguage;
        },
        existingQuestions() {
            return this.$store.state.questionpicker.selectedQuestions.length;
        },
             contactLink(){
            return this.$store.getters.contactLink
        }
    },
};
</script>

<style scoped></style>
