<template>
    <v-list>
        <v-list-group
            :ripple="false"
            v-for="sub in subCategories"
            :key="sub.id"
        >
            <template v-slot:activator>
                <v-list-item-title class="my-2">
                    <span class="font-weight-bold ">
                        {{ translatedText(sub.name, $i18n.locale)}}
                    </span>

                    <div v-if="Object.prototype.hasOwnProperty.call(sub, 'description')" class="mt-1 sub-description">
                        {{ translatedText(sub.description, $i18n.locale)}}
                    </div>
                </v-list-item-title>
            </template>
            <QuestionCollection :subId="sub.id" />
        </v-list-group>
    </v-list>
</template>

<script>
import QuestionCollection from "@/components/questionpicker/QuestionCollection.vue";
import { getSubCategories } from "@/api/catalog.api.js";
export default {
    name: "",
    props: ["categoryId"],
    components: { QuestionCollection },
    mounted() {
        getSubCategories(this.categoryId).then(
            (response) => {
                this.subCategories = response.data;
            },
            (error) => {
                console.log(error);
            }
        );
    },
    data: () => ({
        subCategories: null,
    }),
    methods: {},
    computed: {},
};
</script>

