import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "../plugins/i18n";
import { supportedLangs } from "@/plugins/i18n.js";
import StepperWrapper from "../views/StepperWrapper.vue";
import App from "@/App.vue";
import Questions from "../views/Questions.vue";
import Team from "../views/Team.vue";
import Interval from "../views/Interval.vue";
import BuildSurvey from "../views/BuildSurvey.vue";
import Result from "../views/Result.vue";
import SetOverview from "../views/SetOverview.vue";
import JoinLink from "../views/JoinLink.vue";
import Root from "@/router/Root.vue";
import Success from "../views/Success.vue";
import NotFound from "../views/NotFound.vue";
// import Test from "../views/Test.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        beforeEnter(to, from, next) {
            next(i18n.locale + "/step");
        },
        component: App
    },

    // a bit hacky hardcode, but couldn't figure out how to implement this reroute in /:lang guards
    {
        path: "/en",
        redirect: "/en/step/questions"
    },
    {
        path: "/de",
        redirect: "/de/step/questions"
    },

    {
        path: "/join",
        beforeEnter(to, from, next) {
            // check joining user's browser lang and if we support it (either "de" or "en"), set it as locale
            if (supportedLangs.includes(navigator.language.split("-")[0])) {
                i18n.locale = navigator.language.split("-")[0];
            }

            router.replace({ params: { lang: i18n.locale } });
            let query = "";
            if (to.query.token) {
                query = "?token=" + to.query.token;
            }
            next(i18n.locale + "/join" + query);
        }
    },

    {
        path: "/:lang",
        component: Root,
        beforeEnter(to, from, next) {
            let lang = to.params.lang;
            if (supportedLangs.includes(lang)) {
                if (i18n.locale !== lang) {
                    i18n.locale = lang;
                }
                return next();
            }
            return next(i18n.locale + "/404");
        },
        children: [
            {
                path: "step",
                redirect: "step/questions",
                name: "start",
                component: StepperWrapper,
                children: [
                    {
                        path: "questions",
                        name: "questions",
                        component: Questions
                    },
                    {
                        path: "questions-preview",
                        name: "questions-preview",
                        component: Result
                    },
                    {
                        path: "questions-overview",
                        name: "questions-overview",
                        component: SetOverview
                    },
                    {
                        path: "questions-build",
                        name: "questions-build",
                        component: BuildSurvey
                    },
                    {
                        path: "team",
                        name: "team",
                        component: Team
                    },
                    {
                        path: "interval",
                        name: "interval",
                        component: Interval
                    }
                ]
            },
            {
                path: "join",
                name: "join",
                component: JoinLink
            },
            {
                path: "success",
                name: "success",
                component: Success
            }
        ]
    },
    {
        path: "/404",
        name: "invalid",
        component: NotFound
    },
    {
        path: "*",
        redirect: { name: "invalid" }
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
