<template>
    <div>
        <QuestionItem
            v-for="question in questions"
            :key="question.id"
            :question="question"
        />
    </div>
</template>

<script>
import QuestionItem from "@/components/questionpicker/QuestionItem.vue";
import { getQuestions } from "@/api/catalog.api.js";
export default {
    name: "",
    props: ["subId"],
    components: {
        QuestionItem,
    },
        data: () => ({
        questions: null,
    }),
    created() {
        getQuestions(this.subId).then(
            (response) => {
                this.questions = response.data;
            },
            (error) => {
                console.log(error);
            }
        );
    },

    methods: {},
    computed: {},
};
</script>

