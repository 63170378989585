<template>
    <!-- new user's primary details (team step 1) -->
    <v-stepper-content step="1" class="pl-6">
        <v-card-text class="pt-0">
            {{ $t("details.introText1") }}
            <br />
            {{ $t("details.introText2") }}
        </v-card-text>

        <v-form v-model="isFormValid">
            <v-text-field
                :rules="notEmpty"
                :label="$t('form.firstName')"
                prepend-icon="person"
                v-model="user.firstName"
            ></v-text-field>
            <v-text-field
                :rules="notEmpty"
                :label="$t('form.lastName')"
                prepend-icon="person"
                v-model="user.lastName"
            ></v-text-field>

            <v-text-field
                :rules="emailRules"
                :label="$t('form.email')"
                prepend-icon="email"
                v-model="user.email"
            ></v-text-field>
            <v-slide-y-transition>
                <v-alert
                    v-if="emailExists"
                    type="error"
                    outlined
                    text
                    dense
                    color="error"
                    >{{ $t("team.emailRegistered") }}
                </v-alert>
            </v-slide-y-transition>

            <v-text-field
                :rules="notEmpty"
                :label="$t('form.company')"
                prepend-icon="corporate_fare"
                v-model="user.companyName"
            ></v-text-field>
        </v-form>

        <base-btn
            @click="saveUser"
            :disabled="!isFormValid || emailExists"
            :label="
                didUserChange && isStepCompleted
                    ? $t('buttons.saveChanges')
                    : $t('buttons.next')
            "
            class="ma-2"
        />
    </v-stepper-content>
</template>

<script>
export default {
    props: ["currentStep"],
    data: () => ({
        user: {
            firstName: null,
            lastName: null,
            email: null,
            companyName: null
        },
        isFormValid: false,
        notEmpty: [v => (v && !!v.trim()) || ""],
        emailRules: [
            v => !!v || "",
            v => /.+@.+\..+/.test(v) || "",
            v => (v || "").indexOf(" ") < 0 || ""
        ],
        compareUser: {} //to check if user made any changes
    }),
    created() {
        this.setFields();
    },
    watch: {
        currentStep() {
            //necessary to reset step 1 details back to ones saved in Vuex in case user saves their details, then goes to other steps, comes back, edits and goes away again without pressing "Save changes"
            this.setFields();
        }
    },
    methods: {
        setFields() {
            // retrieves values from Vuex newUser object
            let savedUser = this.$store.state.newUser;
            Object.assign(this.user, {
                firstName: savedUser.firstName,
                lastName: savedUser.lastName,
                companyName: savedUser.companyName,
                email: savedUser.email
            });

            this.compareUser = { ...this.user }; //copy of initial values to see if user made changes
        },
        saveUser() {
            this.$store.commit("setUserDetails", this.user);
            this.$store.commit("setTeamStep", [0, true]);
            this.$emit("next");
        }
    },
    computed: {
        isStepCompleted() {
            return this.$store.state.teamCompletionState[0];
        },
        existingEmails() {
            return this.$store.getters.existingTeamEmails;
        },
        emailExists() {
            // checks if currently entered e-mail is already in use
            return (
                this.user.email && this.existingEmails.includes(this.user.email.toLowerCase())
            );
        },
        didUserChange() {
            // checks whether user made changes to input fields. implemented along with isStepCompleted to change button label from "Next" to "Save changes" to make it clear to the user that when they return to step 1 to edit some details, they won't be saved until edited entries are valid and the button is pressed
            return (
                this.user.email !== this.compareUser.email ||
                this.user.companyName !== this.compareUser.companyName ||
                this.user.firstName !== this.compareUser.firstName ||
                this.user.lastName !== this.compareUser.lastName
            );
        }
    }
};
</script>
