import httpClient from './httpClient';

const END_POINT = '/catalog';


const getTopCategories = () => httpClient.get(END_POINT);
const getCategory = (category_id) => httpClient.get(`${END_POINT}/${category_id}`)
const getCategorySet = (category_id) => httpClient.get(`${END_POINT}/${category_id}/set`)

const getSubCategories = (category_id) => httpClient.get(`${END_POINT}/${category_id}/subcategories`)
const getQuestions = (category_id) => httpClient.get(`${END_POINT}/${category_id}/questions`)


export {
    getTopCategories,
    getCategory,
    getSubCategories,
    getQuestions,
    getCategorySet
}
