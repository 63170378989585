<template>
    <span class="ml-1 text-uppercase">
        <!-- if only one language set -->
        <span v-if="typeof surveyLang=== 'string'">
            <Flag :locale="surveyLang"/>
            {{ surveyLang}}
        </span>

        <span v-else>
            <Flag :locale="surveyLang[0]"/>
            {{ surveyLang[0] }}
            <span class="mx-2">+</span>
             <Flag :locale="surveyLang[1]"/>
            {{ surveyLang[1] }}
        </span>
    </span>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
    name: "",
    props: ["surveyLang"],
    components: {},
    data: () => ({}),
    methods: {},
    computed: {},
};
</script>

<style></style>
