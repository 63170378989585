<template>
    <div class="mt-3">
        <v-card class="fade-in " color="#f5f5f5">
            <v-card-text class="pt-0">
                <!-- avatar row -->
                <v-row no-gutters justify="start">
                    <v-col class="mt-5">
                        <span class="d-flex align-center">
                         <v-img
                                :src="
                                    require(`@/assets/images/avatars/book/5.png`)
                                "
                                max-height="30"
                                max-width="25"
                                contain
                            ></v-img>
                        {{ $t("questions.picture") }}</span>
                        <v-select
                            hide-details
                            solo
                            flat
                            v-model="questionAvatar"
                            :items="avatars"
                        >
                            <template v-slot:item="data">
                                <v-row no-gutters align="center">
                                    <v-col style="max-width:30px" class="mr-1">
                                        <v-img
                                            contain
                                            :src="
                                                require(`@/assets/images/avatars/${data.item}/5.png`)
                                            "
                                        ></v-img>
                                    </v-col>
                                    <v-col>{{ data.item }}</v-col>
                                </v-row>
                            </template>
                        </v-select>
                    </v-col>

                    <v-col>
                        <div
                            @click="showDemo = true"
                            class="py-0 pl-3 d-flex align-center hover-pointer"
                        >
                            <v-img
                                :key="questionAvatar"
                                :src="
                                    require(`@/assets/images/avatars/${questionAvatar}/5.png`)
                                "
                                max-height="100"
                                max-width="100"
                                contain
                                class="avatar"
                            ></v-img>

                            <div class="demoLink">
                                <v-icon>
                                    mdi-cursor-default-click-outline
                                </v-icon>
                                demo
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <!-- /avatar row -->

                <!-- 1-LANGUAGE QUESTION -->
                <div v-if="singleLanguage" class="mb-3">
                    <v-form v-model="isFilled">
                        <v-row>
                            <v-col class="pt-0">
                                <Flag :locale="surveyLang" />
                                {{ $t("questions.title") }}
                                <v-text-field
                                    :rules="notEmpty"
                                    v-model="question.title"
                                    hide-details
                                    solo
                                    flat
                                    class="questionTitle"
                                    :label="$t('questions.title')"
                                    :placeholder="
                                        $t('questions.example') +
                                            $t('questions.exampleT')
                                    "
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="py-0">
                                 <Flag :locale="surveyLang" />
                                {{ $t("questions.question") }}
                                <v-text-field
                                    :rules="notEmpty"
                                    hide-details
                                    solo
                                    flat
                                    v-model="question.questionText"
                                    :label="$t('questions.question')"
                                    :placeholder="
                                        $t('questions.example') +
                                            $t('questions.exampleQ')
                                    "
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
                <!-- 1-LANGUAGE QUESTION -->

                <!-- 2-LANGUAGE QUESTION -->
                <div v-else class="mb-4">
                    <v-form v-model="isMultiFilled">
                        <v-row>
                            <v-col class="pt-0">
                                <Flag :locale="'de'" />
                                {{ $t("questions.title") }}
                                <v-text-field
                                    :rules="notEmpty"
                                    v-model="multiQuestion.title.de"
                                    hide-details
                                    solo
                                    flat
                                    class="questionTitle"
                                    :label="$t('questions.title')"
                                    :placeholder="
                                        $t('questions.example') +
                                            $t('questions.exampleT')
                                    "
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="py-0">
                                <Flag :locale="'de'" />
                                {{ $t("questions.question") }}
                                <v-text-field
                                    :rules="notEmpty"
                                    hide-details
                                    solo
                                    flat
                                    v-model="multiQuestion.questionText.de"
                                    :label="$t('questions.question')"
                                    :placeholder="
                                        $t('questions.example') +
                                            $t('questions.exampleQ')
                                    "
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-divider class="my-3"></v-divider>

                        <v-row>
                            <v-col class="pt-0">
                                <Flag :locale="'en'" />
                                {{ $t("questions.title") }}
                                <v-text-field
                                    :rules="notEmpty"
                                    v-model="multiQuestion.title.en"
                                    hide-details
                                    solo
                                    flat
                                    class="questionTitle"
                                    :label="$t('questions.title')"
                                    :placeholder="
                                        $t('questions.example') +
                                            $t('questions.exampleT')
                                    "
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="py-0">
                                <Flag :locale="'en'" />
                                {{ $t("questions.question") }}
                                <v-text-field
                                    :rules="notEmpty"
                                    hide-details
                                    solo
                                    flat
                                    v-model="multiQuestion.questionText.en"
                                    :label="$t('questions.question')"
                                    :placeholder="
                                        $t('questions.example') +
                                            $t('questions.exampleQ')
                                    "
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
                <!-- /2-LANGUAGE QUESTION -->

                <v-row class="mx-1" justify="center">
                    <v-btn
                        depressed
                        color="primary"
                        @click="saveQuestion"
                        :disabled="!isFormValid"
                    >
                        {{ $t("buttons.saveQuestion") }}
                    </v-btn>
                </v-row>
            </v-card-text>
        </v-card>

        <AvatarDemo
            :questionText="question.questionText"
            :showDemo="showDemo"
            :questionAvatar="questionAvatar"
            @demoStatus="showDemo = $event"
            @avatarChanged="questionAvatar = $event"
        />
    </div>
</template>

<script>
import AvatarDemo from "@/components/AvatarDemo.vue";
export default {
    name: "",
    props: [""],
    components: { AvatarDemo },
    data: () => ({
        isFilled: false,
        isMultiFilled: false,
        question: {
            title: null,
            questionText: null,
        },
        multiQuestion: {
            title: {
                de: null,
                en: null,
            },
            questionText: {
                de: null,
                en: null,
            },
        },
        showDemo: false,
        questionAvatar: "kitty", //default

        notEmpty: [(v) => (v && !!v.trim()) || ""],
    }),
    methods: {
        saveQuestion() {
            // when user makes single-language question
            if (this.singleLanguage) {
                // console.log("creating q: single language");
                let question = {
                    title: {},
                    questionText: {},
                    icon: this.questionAvatar,
                };

                question.questionText[
                    `${this.surveyLang}`
                ] = this.question.questionText;
                question.title[`${this.surveyLang}`] = this.question.title;
                this.$store.dispatch("questionpicker/addQuestion", question);

                // when multilingual question
            } else {
                console.log("multi language");
                let question = {
                    title: this.multiQuestion.title,
                    questionText: this.multiQuestion.questionText,
                    icon: this.questionAvatar,
                };

                this.$store.dispatch("questionpicker/addQuestion", question);
            }

            this.resetFields();
        },
        resetFields() {
            this.question = {
                title: null,
                questionText: null,
            };
            this.multiQuestion = {
                title: {
                    de: null,
                    en: null,
                },
                questionText: {
                    de: null,
                    en: null,
                },
            };
        },
    },
    computed: {
        avatars() {
            return this.$store.state.questionpicker.avatars;
        },
        surveyLang() {
            return this.$store.state.surveyLanguage;
        },
        singleLanguage() {
            return typeof this.surveyLang === "string";
        },
        isFormValid() {
            return this.singleLanguage ? this.isFilled : this.isMultiFilled;
        },
    },
};
</script>

<style></style>
