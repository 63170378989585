<template>
    <div class="text-center">
        <h4>{{$t('notFound')}}</h4>
        <v-img
            class="pop mx-auto mt-1"
            :src="require(`@/assets/images/setIcons/nodata.png`)"
            max-width="200"
            contain
        ></v-img>
    </div>
</template>

<script>
export default {
    name: "",
    props: [""],
    components: {},
    data: () => ({}),
    methods: {},
    computed: {},
};
</script>

<style></style>
