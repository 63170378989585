<template>
    <div class="px-0 px-sm-2">

        <v-row justify="center" class="mb-10 mx-0 pos-relative">
            <div
                v-for="(step, i) in signupSteps"
                :key="i"
                class="d-flex flex-nowrap align-center"
            >
                <div
                    class="d-flex flex-column align-center hover-pointer"
                    @click="navigate(step)"
                >
                    <v-avatar
                        :size="currentStep == i ? '35' : '30'"
                        :color="
                            currentStep == i || completed[i]
                                ? 'accent-blue'
                                : 'grey lighten-1'
                        "
                        :class="{ 'quick-pop': currentStep == i }"
                        class="transitioned"
                    >
                        <span v-if="completed[i]">
                            <v-icon color="green lighten-1" class="big-pop">
                                mdi-check-bold
                            </v-icon>
                        </span>
                        <span v-else class="stepNumber">{{ i + 1 }}</span>
                    </v-avatar>

                    <div
                        class="stepLabel"
                        :class="{
                            'secondary--text':
                                !completed[i] && currentStep !== i, 'd-none': currentStep !== i && $vuetify.breakpoint.xs
                        }"
                        v-text="$t(step.text)"
                    ></div>
                </div>

                <div
                    :class="completed[i] ? 'accent-blue' : 'grey lighten-1'"
                    v-if="i < signupSteps.length - 1"
                    class="stepSeparator"
                ></div>
            </div>
        </v-row>

        <v-container class="mb-0">
            <v-slide-x-reverse-transition :hide-on-leave="true">
                <router-view></router-view>
            </v-slide-x-reverse-transition>
        </v-container>
    </div>
</template>

<script>
export default {
    created() {
        this.stepIndicator();
    },
    components: {},
    data: () => ({
        currentStep: 0,
        signupSteps: [
            { text: "questions.stepperLabel", path: "questions" },
            { text: "team.stepperLabel", path: "team" },
            { text: "interval.stepperLabel", path: "interval" },
        ],
    }),
    methods: {
        navigate(step) {
            if (step.path == "questions" && this.completed[0]) {
                // see selected questions not landing page if 1st step is completed
                this.$router.push({ name: "questions-overview" });
                this.stepIndicator();
            } else if (!this.routerURL.includes(step.path)) {
                this.$router.push({ name: step.path });
                this.stepIndicator();
            } else return;
        },

        stepIndicator() {
            // checks URL and updates step indicator accordingly
            switch (this.routerURL) {
                case "questions":
                    this.currentStep = 0;
                    break;
                case "questions-preview":
                    this.currentStep = 0;
                    break;
                case "questions-overview":
                    this.currentStep = 0;
                    break;
                case "questions-build":
                    this.currentStep = 0;
                    break;
                case "team":
                    this.currentStep = 1;
                    break;
                case "interval":
                    this.currentStep = 2;
            }
        },
    },
    computed: {
        routerURL() {
            // retrieves current URL address end
            // either questions, questions-preview, questions-overview, questions-build, team or interval
            let path = this.$route.path.split("/");
            return path[path.length - 1].toLowerCase();
        },
        completed() {
            return this.$store.state.completionState;
        },
    },
    watch: {
        routerURL() {
            // when address changes, run a step indicator check
            this.stepIndicator();
        },
    },
};
</script>

<style>
.stepLabel {
    position: absolute;
    top: 40px;
    margin: 1px;
}

.stepSeparator {
    width: 120px;
    height: 3px;
}

.stepNumber {
    color: white;
    font-size: 1rem;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {
    .stepSeparator {
        width: 70px;
    }
}
</style>
