<template>
    <v-dialog
        :fullscreen="$vuetify.breakpoint.xs"
        v-model="demoStatus"
        max-width="650"
        scrollable
    >
        <v-card :tile="$vuetify.breakpoint.xs">
            <v-card tile dark class="red-gradient">
                <v-btn dark icon @click="closeDemo" class="closeBtn">
                    <v-icon large color="grey lighten-2">mdi-close</v-icon>
                </v-btn>

                <v-container>
                    {{ $t("demo.intro") }}

                    <v-row>
                        <v-col style="max-width: 200px">
                            <v-select
                                hide-details
                                filled
                                :items="avatars"
                                v-model="picture"
                                @change="handleChange"
                            >
                                <!-- @change="$emit('avatarChanged', picture)" -->
                                <template v-slot:item="data">
                                    <v-row no-gutters align="center">
                                        <v-col
                                            style="max-width:30px"
                                            class="mr-1"
                                        >
                                            <v-img
                                                contain
                                                :src="
                                                    require(`@/assets/images/avatars/${data.item}/5.png`)
                                                "
                                            ></v-img>
                                        </v-col>
                                        <v-col>{{ data.item }}</v-col>
                                    </v-row>
                                </template>
                            </v-select>
                            <div
                                v-show="changeMessage"
                                class="big-pop green--text"
                                style="position: absolute; bottom: 9px"
                            >
                                {{ $t("demo.pictureChanged") }}
                            </div>
                        </v-col>

                        <v-col class="d-flex align-center mb-9 pt-6">
                            <v-img
                                class="pulse-slow"
                                max-width="50"
                                contain
                                :src="require(`@/assets/images/arrow.png`)"
                            ></v-img>
                            <div class="ml-2">{{ $t("demo.favourite") }}</div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>

            <v-card-text>
                <v-row>
                    <v-card-text>
                        <p class="subheading text-center">
                            {{
                                questionText
                                    ? questionText
                                    : $t("questions.exampleQ")
                            }}
                        </p>

                        <v-img
                            :src="
                                require(`@/assets/images/avatars/${picture}/${rating}.png`)
                            "
                            height="350"
                            contain
                        ></v-img>

                        <v-slider
                            :tick-labels="[1, 2, 3, 4, 5, 6]"
                            track-color="grey"
                            :thumb-color="color"
                            name="rating"
                            v-model="rating"
                            :color="color"
                            step="1"
                            min="1"
                            max="6"
                            ticks="always"
                            tick-size="3"
                            thumb-label
                            always-dirty
                            class="mb-2 mx-2"
                        ></v-slider>

                        <v-textarea
                            :value="$t('demo.label')"
                            solo
                            auto-grow
                            counter="280"
                        ></v-textarea>

                        <v-col>
                            <div class="d-flex align-center">
                                <v-switch
                                    v-model="publicComment"
                                    label="Public comment"
                                    color="blue"
                                    hide-details
                                ></v-switch>
                            </div>
                        </v-col>
                    </v-card-text>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                    <v-card-text>
                        <v-row>
                            <v-progress-circular
                                value="10"
                                color="blue"
                                size="20"
                                :width="2"
                                :rotate="-90"
                            ></v-progress-circular>
                            1/7

                            <v-spacer></v-spacer>
                            <v-btn text color="blue">
                                {{ $t("buttons.back") }}
                            </v-btn>
                            <v-btn dark color="blue">
                                {{ $t("buttons.next") }}
                            </v-btn>
                        </v-row>
                    </v-card-text>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "",
    props: ["questionAvatar", "showDemo", "questionText"],
    components: {},
    data: () => ({
        publicComment: true,
        changeMessage: false,
        rating: 5,
        picture: null,
    }),
    created() {
        this.picture = this.questionAvatar;
    },
    methods: {
        closeDemo() {
            this.$emit("demoStatus", false);
        },
        handleChange() {
            this.$emit("avatarChanged", this.picture);

            this.changeMessage = true;
            setTimeout(() => {
                this.changeMessage = false;
            }, 1500);
        },
    },
    watch: {
        questionAvatar(val) {
            this.picture = val;
        },
    },
    computed: {
        avatars() {
            return this.$store.state.questionpicker.avatars;
        },
        color() {
            if (this.rating > 4) return "green";
            if (this.rating > 3) return "orange";
            return "red";
        },
        demoStatus: {
            // open/close dialog logic
            get() {
                return this.showDemo;
            },
            set() {
                this.closeDemo();
            },
        },
    },
};
</script>

<style>
.demoFav {
    top: 60px;
    left: 40%;
}
.v-slider__tick-label {
    margin-top: 6px;
}

.closeBtn {
    position: absolute;
    right: 10px;
    top: 10px;
}
</style>
