<template>
    <v-row justify="center" no-gutters class="fade-in">
        <v-col cols="12" md="10" lg="7" xl="5">
            <p class="text-center subheading">{{ $t("team.setUp") }}</p>

            <v-img
                class="mx-auto slide-in-left"
                :src="require(`@/assets/images/group.png`)"
                max-height="150"
                contain
            ></v-img>

            <v-stepper vertical v-model="currentStep" class="elevation-0">
                <!-- step labels-->

                <v-stepper-step
                    :complete="teamCompleted[0]"
                    color="accent"
                    editable
                    edit-icon="check"
                    step="1"
                >
                    <span
                        class="pl-3"
                        :class="{ 'font-weight-bold': currentStep == 1 }"
                    >
                        {{ $t("details.label") }}
                    </span>
                </v-stepper-step>
                <UserDetails :currentStep="currentStep" @next="currentStep = 2" />

                <v-stepper-step
                    :class="{ 'font-weight-bold': currentStep == 2 }"
                    :complete="teamCompleted[1]"
                    color="accent"
                    editable
                    edit-icon="check"
                    step="2"
                >
                    <span class="pl-3">{{ $t("team.teamName") }}</span>
                </v-stepper-step>
                <TeamName @next="currentStep = 3" />

                <!-- step 3-->
                <v-stepper-step
                    editable
                    step="3"
                    color="accent"
                    :class="{ 'font-weight-bold': currentStep == 3 }"
                    :complete="teamCompleted[2]"
                    edit-icon="check"
                >
                    <span class="pl-3">
                        {{
                            currentStep == 3 && teamName
                                ? $t("team.addMembersTo") + " " + teamName
                                : $t("team.addMembers")
                        }}
                    </span>
                </v-stepper-step>
                <!-- content -->
                <AddMembers
                    @next="saveTeam"
                    :readyForInterval="readyForInterval()"
                />
                <!-- /step 3 -->
            </v-stepper>
        </v-col>
    </v-row>
</template>

<script>
import UserDetails from "@/components/UserDetails";
import AddMembers from "@/components/AddMembers";
import TeamName from "@/components/TeamName";
export default {
    name: "Team",
    components: { UserDetails, AddMembers, TeamName },
    data: () => ({
        currentStep: 1
    }),
    methods: {
        saveTeam() {
            this.$store.commit("setStep", [1, true]); //overall step status
            this.$store.commit("setTeamStep", [2, true]); //team creations status
            this.$router.push({ name: "interval" });
        },
        readyForInterval() {
            // returns true if personal details and team name are filled out
            return this.teamCompleted[0] && this.teamCompleted[1];
        }
    },
    computed: {
        teamName() {
            return this.$store.state.newUser.teamName;
        },
        teamCompleted() {
            // starts with [false, false, false], tracking if steps are filled out
            return this.$store.state.teamCompletionState;
        }
    }
};
</script>

<style>
.v-stepper__step .v-ripple__container {
    display: none !important;
}

.v-stepper,
.v-stepper__step--editable:hover {
    background: none !important;
}
</style>
