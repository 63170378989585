<template>
    <div>
        <!-- background graphics -->
        <div v-if="$vuetify.breakpoint.smAndUp">
            <svg
                class="pos-absolute d-print-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
                preserveAspectRatio="xMinYMin meet"
            >
                <path
                    fill="#CFB4BF"
                    fill-opacity="0.1"
                    d="M0,64L80,74.7C160,85,320,107,480,149.3C640,192,800,256,960,277.3C1120,299,1280,277,1360,266.7L1440,256L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
                />
            </svg>

            <svg
                class="pos-absolute d-print-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
                preserveAspectRatio="xMinYMin meet"
            >
                <path
                    fill="#E3E1E1"
                    fill-opacity="0.3"
                    d="M0,224L120,224C240,224,480,224,720,202.7C960,181,1200,139,1320,117.3L1440,96L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
                />
            </svg>
        </div>

        <div v-else>
            <svg
                class="pos-absolute d-print-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 240 1220"
                preserveAspectRatio="xMinYMin meet"
            >
                <path
                    fill="#CFB4BF"
                    fill-opacity="0.1"
                    d="M0,64L80,74.7C160,85,320,107,480,149.3C640,192,800,256,960,277.3C1120,299,1280,277,1360,266.7L1440,256L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
                />
            </svg>

            <svg
                class="pos-absolute d-print-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 490 1220"
                preserveAspectRatio="xMinYMin meet"
            >
                <path
                    fill="#E3E1E1"
                    fill-opacity="0.3"
                    d="M0,224L120,224C240,224,480,224,720,202.7C960,181,1200,139,1320,117.3L1440,96L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
                />
            </svg>
        </div>
        <!-- /background graphics -->
    </div>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
    name: "",
    props: [""],
    components: {},
    data: () => ({}),
    methods: {},
    computed: {},
};
</script>

<style></style>
