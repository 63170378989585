import httpClient from './httpClient';
const END_POINT = '/onboarding';

const register = (registerRequest) => httpClient.post(`${END_POINT}/register`, registerRequest);
const teamInfo = (tokenRequest) => httpClient.post(`${END_POINT}/info`, tokenRequest);
const joinTeam = (joinRequest) => httpClient.post(`${END_POINT}/join`, joinRequest);


export {
    register,
    teamInfo,
    joinTeam
}
