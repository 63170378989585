<template>
    <v-btn 
        depressed
        :disabled="disabled"
        color="primary"
        class="hover-btn no-text-transform"
        :ripple="false"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <span style="white-space: normal;">{{ label }}</span>
    </v-btn>
</template>

<script>
export default {
    name: "",
    props: ["label", "disabled"],
    components: {},
    data: () => ({}),
    methods: {},
    computed: {},
};
</script>

<style>
.hover-btn {
    transition: 0.1s linear all;
}

.hover-btn:hover {
    transform: scale(1.02) !important;
    background: linear-gradient(
        150deg,
        rgba(201, 88, 126, 1) 0%,
        rgba(160, 48, 88, 1) 100%
    ) !important;
}

.hover-btn:hover span {
    transform: scale(1) !important;
}
</style>
