<template>
    <div class="fade-in">
        <!-- <div v-for="q in selectedQuestions" :key="q.id">
            <v-icon>mdi-circle</v-icon>
            {{ q }}
        </div> -->

        <v-btn outlined color="grey" @click="goBack">
            {{ $t("buttons.back") }}
        </v-btn>
        <v-row>
            <v-col>
                <v-row>
                    <v-col cols="12" md="6">
                        <CategoryBrowser />
                    </v-col>

                    <v-col cols="12" md="6">
                        <!-- selected questions -->
                        <div class="floatRight">
                        <v-card class="pb-3 fade-in" color="grey lighten-4">
                            <!-- survey language changer -->
                            <LanguageSet />

                            <v-container>
                                <v-scale-transition mode="out-in">
                                    <v-alert
                                        dismissible
                                        v-model="infoBox"
                                        dense
                                        outlined
                                        text
                                        color="info"
                                    >
                                        <i18n
                                            path="questions.dragDrop"
                                            class="mr-1"
                                        >
                                            <v-icon small>
                                                mdi-drag-variant
                                            </v-icon>
                                            >
                                        </i18n>

                                        <i18n
                                            path="questions.clickPicture"
                                            class="mr-1"
                                        >
                                            <span>
                                                <v-img
                                                    :src="
                                                        require(`@/assets/images/avatars/target/5.png`)
                                                    "
                                                    max-height="24px"
                                                    max-width="24px"
                                                    contain
                                                    style="display: inline-block; margin-bottom: -5px"
                                                ></v-img>
                                            </span>
                                            >
                                        </i18n>
                                        {{ $t("questions.weRecommend") }}
                                    </v-alert>
                                </v-scale-transition>

                                <div v-if="selectedQuestions.length < 1">
                                    <div
                                        class="text-center text-caption subtext"
                                    >
                                        {{ $t("questions.noQuestions") }}
                                    </div>

                                    <v-img
                                        class="mx-auto pop"
                                        :src="
                                            require('@/assets/images/addquestions.png')
                                        "
                                        max-width="350"
                                        contain
                                    ></v-img>
                                </div>

                                <draggable
                                    class="list-group"
                                    v-model="selectedQuestions"
                                    v-bind="dragOptions"
                                    @start="drag = true"
                                    @end="drag = false"
                                >
                                    <transition-group
                                        name="list-complete"
                                        tag="div"
                                    >
                                        <SelectedQuestion
                                            class="list-complete-item"
                                            v-for="(question,
                                            i) in selectedQuestions"
                                            :key="`selectedQ-${i}`"
                                            :question="question"
                                            :i="i"
                                            :mode="'build'"
                                        />
                                    </transition-group>
                                </draggable>

                                <v-row justify="center">
                                    <v-tooltip
                                        bottom
                                        :disabled="selectedQuestions.length >= 2"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <base-btn
                                                    large
                                                    @click="next"
                                                    :disabled="
                                                        selectedQuestions.length <
                                                            2
                                                    "
                                                    :label="
                                                        $t(
                                                            'questions.continueSet'
                                                        )
                                                    "
                                                    class="mt-2"
                                                />
                                            </div>
                                        </template>
                                        <span>
                                            {{ $t("questions.minimum") }}
                                        </span>
                                    </v-tooltip>
                                </v-row>
                            </v-container>
                        </v-card>
                        <!-- /selected questions -->

                        <v-tooltip
                            :disabled="!setFull"
                            bottom
                            color="transparent"
                        >
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <v-btn
                                        @click="
                                            showCreateQuestion = !showCreateQuestion
                                        "
                                        text
                                        class="mt-2"
                                        :ripple="false"
                                        :disabled="setFull"
                                    >
                                        <v-icon small left>
                                            {{
                                                showCreateQuestion
                                                    ? "remove_circle_outline"
                                                    : "add_circle_outline"
                                            }}
                                        </v-icon>
                                        {{ $t("questions.createCustom") }}
                                    </v-btn>
                                </div>
                            </template>
                            <v-card dark color="grey darken-1">
                                <v-card-text>
                                    <div class="mb-1">
                                        {{ $t("questions.setFull") }}
                                        {{ $t("questions.pleaseRemove") }}
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-tooltip>

                        <v-scale-transition>
                            <AddQuestion
                                v-show="showCreateQuestion && !setFull"
                                @close="showCreateQuestion = false"
                            />
                        </v-scale-transition>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import CategoryBrowser from "@/components/questionpicker/CategoryBrowser.vue";
import AddQuestion from "@/components/questionpicker/AddQuestion";
import LanguageSet from "@/components/LanguageSet";
import SelectedQuestion from "@/components/questionpicker/SelectedQuestion.vue";
import draggable from "vuedraggable";
export default {
    props: [""],
    components: {
        CategoryBrowser,
        SelectedQuestion,
        AddQuestion,
        draggable,
        LanguageSet,
    },
    data: () => ({
        showCreateQuestion: false,
        drag: false,
        infoBox: true,
    }),
    methods: {
        next() {
            this.$store.commit("setStep", [0, true]);
            this.$router.push({ name: "team" });
        },
        goBack() {
            this.$router.push({ name: "start" });
            this.$store.dispatch("questionpicker/resetList");
        },
    },
    computed: {
        setFull() {
            return this.selectedQuestions.length >= 12;
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
            };
        },
        selectedQuestions: {
            get() {
                return this.$store.state.questionpicker.selectedQuestions;
            },
            set(value) {
                // change order with drag/drop
                this.$store.dispatch("questionpicker/setList", value);
            },
        },
    },
};
</script>

<style>
/* keep questions floating on the right size within view as user scrolls */
.floatRight{
position: -webkit-sticky;
position: sticky;
top: 20px;
}
</style>
