<template>
    <!-- new user's team name (team step 2) -->
    <v-stepper-content step="2" class="pl-6">
        <v-card-text class="pt-0">{{ $t("form.multiple") }}</v-card-text>
        <v-form v-model="isFormValid">
            <v-row>
                <v-col>
                    <v-text-field
                        @keyup.enter="next"
                        :rules="[rules.required]"
                        v-model="teamName"
                        :placeholder="$t('form.teamPlaceholder')"
                        prepend-icon="mdi-account-group"
                    ></v-text-field>
                </v-col>
                <v-col  v-show="$vuetify.breakpoint.smAndUp">
                    <v-img
                        class="pop"
                        :src="require(`@/assets/images/pen-clean.png`)"
                        max-width="80"
                        contain
                    ></v-img>
                </v-col>
            </v-row>
        </v-form>

        <base-btn
            @click="next"
            :disabled="!isFormValid"
            :label="$t('buttons.next')"
            class="ma-2"
        />
    </v-stepper-content>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
    data: () => ({
        isFormValid: false,
        rules: {
            required: (v) => (v && !!v.trim()) || "",
        },
    }),
    methods: {
        next() {
            this.$emit("next");
        },
    },
    watch: {
        isFormValid(val) {
            this.$store.commit("setTeamStep", [1, val]);
        },
    },
    computed: {
        // two-way binding with vuex state so that values could be edited at any point
        teamName: {
            get() {
                return this.$store.state.newUser.teamName;
            },
            set(value) {
                this.$store.commit("updateTeamName", value);
            },
        },
    },
};
</script>
