import Vue from "vue";
import Vuex from "vuex";
import i18n from "@/plugins/i18n";
import questionpicker from "./modules/questionpicker";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        questionpicker
    },
    strict: true,
    state: {
        completionState: [false, false, false], //tracking signup step completion
        teamCompletionState: [false, false, false], //tracking team setup completion
        registerResponse: null,
        surveyLanguage: null,
        newUser: {
            companyName: null,
            firstName: null,
            lastName: null,
            email: null,
            language: i18n.locale,
            teamName: null,
            teamMembers: [],
            surveyInterval: null,
            surveyStartDate: null,
            startNow: false,
            questionList: [],
            acceptTerms: false
        },
        contactLinks: {
            de: "https://joineer.com/ueber-uns/#kontaktdaten",
            en: "https://joineer.com/en/about-us/#kontaktdaten"
        }
    },
    getters: {
        //helper to display questions in chosen surveyLang even if UI locale is different. if multilingual, defaults to UI locale
        questionDisplayLang: state => {
            if (state.surveyLanguage !== i18n.locale) {
                if(typeof state.surveyLanguage === "string"){
                    return state.surveyLanguage;
                } else {
                    return i18n.locale
                }
            }
            //fallback to locale
            else {
                return i18n.locale;
            }
        },
        allExistingEmails: state => {
            //used when adding a new team member
            let emails = [];
            state.newUser.teamMembers.forEach(member => {
                emails.push(member.email);
            });
            if (state.newUser.email) {
                // let email = state.newUser.email;
                // email.toLowerCase();
                emails.push(state.newUser.email.toLowerCase());
            }
            return emails;
        },
        existingTeamEmails: state => {
            //used in team/step1 in case user edits e-mail.
            let emails = [];
            state.newUser.teamMembers.forEach(member => {
                emails.push(member.email);
            });
            return emails;
        },
        contactLink: state => {
            if (Object.prototype.hasOwnProperty.call(state.contactLinks, i18n.locale)) {
                return state.contactLinks[i18n.locale]
            } else {
                return state.contactLinks.en
            }

        }
    },
    mutations: {
        setUserDetails(state,user){
            // sets firstName, lastName, companyName, email
            Object.assign(state.newUser, user)
        },
        updateTeamName(state, name) {
            state.newUser.teamName = name.trim();
        },
        addMember(state, member) {
            state.newUser.teamMembers.push(member);
        },
        removeMember(state, index) {
            state.newUser.teamMembers.splice(index, 1);
        },
        setInterval(state, interval) {
            state.newUser.surveyInterval = interval;
        },
        setDate(state, date) {
            state.newUser.surveyStartDate = date;
        },
        startNow(state, value) {
            state.newUser.startNow = value;
        },
        setStep(state, [i, status]) {
            Vue.set(state.completionState, i, status)
        },
        // on /team subdirectory (Details, Team name, Add members)
        setTeamStep(state, [i, status]) {
            Vue.set(state.teamCompletionState, i, status)
            // state.teamCompletionState[i] = status;
        },
        setQuestionList(state) {
            state.newUser.questionList = state.questionpicker.selectedQuestions;
        },
        setRegisterResponse(state, response) {
            state.registerResponse = response;
        },
        setSurveyLanguage(state, lang) {
            state.surveyLanguage = lang;
        },
        setUserLanguage(state, lang) {
            state.newUser.language = lang;
        },
        setTerms(state, value) {
            state.newUser.acceptTerms = value;
        }
    },
    actions: {}
});
