// global functions made available in the Vue instance via mixins
import Vue from 'vue';

Vue.mixin({
    methods: {
      translatedText(texts, lang) {
        if (texts) {
            if (typeof texts == "object") {
                return Object.prototype.hasOwnProperty.call(texts, lang)
                    ? texts[lang]
                    : Object.values(texts)[0]
            }

            if (typeof texts == "string") {
                return texts;
            }
        } else {
            return null;
        }
    }

    },
  })
