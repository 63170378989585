<template>
    <v-container>
        <div v-if="!joinComplete">
            <v-row justify="center" no-gutters class="fade-in" v-if="getToken">
                <v-col cols="12" md="10" lg="7" xl="5">
                    <div class="text-center subheading">
                        <i18n path="join.joinTeam">
                            <span>{{ teamName }}</span>
                        </i18n>
                    </div>
                    <div class="text-center">
                        <i18n path="join.invited">
                            <span>
                                {{ ownerFirstName }} {{ ownerLastName }}
                            </span>
                            <span>{{ companyName }}</span>
                        </i18n>
                    </div>

                    <v-img
                        class="mx-auto slide-in"
                        :src="require(`@/assets/images/group.png`)"
                        :max-height="$vuetify.breakpoint.xs ? 100 : 150"
                        contain
                    ></v-img>
                    <v-expand-transition>
                        <v-container v-show="showCarousel" class="pa-0">
                            <v-card class="lightpink pos-relative">
                                <v-sheet dark class="pa-3" color="accent">
                                    <div class="text-center">
                                        {{ $t("join.how") }}
                                    </div>
                                    <v-btn
                                        class="pos-absolute"
                                        style="top: 2px; right: 2px"
                                        icon
                                        @click="showCarousel = false"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-sheet>
                                <v-card-text>
                                    <v-carousel
                                        light
                                        cycle
                                        interval="6000"
                                        height="300"
                                        hide-delimiter-background
                                        :show-arrows="false"
                                    >
                                        <v-carousel-item
                                            v-for="(slide, i) in slides"
                                            :key="i"
                                        >
                                            <v-card
                                                color="transparent"
                                                height="100%"
                                                flat
                                            >
                                                <v-card-text class="pt-0">
                                                    <v-row>
                                                        <v-col>
                                                            <div
                                                                class="subheading"
                                                            >
                                                                {{
                                                                    $t(
                                                                        "join.step"
                                                                    )
                                                                }}
                                                                {{ i + 1 }}
                                                            </div>
                                                            <div
                                                                class="mt-5"
                                                                v-text="
                                                                    $t(
                                                                        slide.text
                                                                    )
                                                                "
                                                            ></div>
                                                            <!-- {{ slide.text }} -->
                                                        </v-col>
                                                        <v-col
                                                            style="max-width: 255px"
                                                        >
                                                            <v-img
                                                                class="mx-auto mt-5"
                                                                :src="
                                                                    require(`@/assets/images/${slide.image}.png`)
                                                                "
                                                                contain
                                                                :max-width="
                                                                    $vuetify
                                                                        .breakpoint
                                                                        .xs
                                                                        ? 150
                                                                        : 250
                                                                "
                                                            ></v-img>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-carousel-item>
                                    </v-carousel>
                                </v-card-text>
                            </v-card>
                        </v-container>
                    </v-expand-transition>

                    <v-form v-model="isFormValid">
                        <v-text-field
                            class="mt-8"
                            :rules="notEmpty"
                            :label="$t('form.firstName')"
                            prepend-icon="person"
                            v-model="firstName"
                        ></v-text-field>
                        <v-text-field
                            :rules="notEmpty"
                            :label="$t('form.lastName')"
                            prepend-icon="person"
                            v-model="lastName"
                        ></v-text-field>
                        <v-text-field
                            :rules="emailRules"
                            :label="$t('form.email')"
                            prepend-icon="email"
                            v-model="email"
                        ></v-text-field>
                    </v-form>

                    <v-row justify="center" no-gutters class="mt-12" >
                        <v-checkbox v-model="acceptTerms">
                            <template v-slot:label>
                                <div>
                                    {{ $t("team.acceptTerms") }}
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <a
                                                target="_blank"
                                                href="https://joineer.com/datenschutz/"
                                                @click.stop
                                                v-on="on"
                                            >
                                                {{ $t("team.terms") }}
                                            </a>
                                        </template>
                                        {{ $t("team.termsTooltip")}}
                                    </v-tooltip>
                                </div>
                            </template>
                        </v-checkbox>
                    </v-row>


                    <base-btn
                        @click="join"
                        :disabled="!isFormValid || !acceptTerms"
                        :label="$t('buttons.saveJoin')"
                        class="d-flex mx-auto"
                    />

                    <v-scale-transition>
                        <v-alert
                            width="400"
                            class="my-5 mx-auto"
                            outlined
                            v-if="errorMessage"
                            type="error"
                            text
                        >
                            {{ errorMessage }}
                        </v-alert>
                    </v-scale-transition>
                </v-col>
            </v-row>
            <div v-else class="text-center">
                <p >{{$t('invalid')}}</p>
            </div>
        </div>

        <!-- after successful join & survey in the future -->
        <div v-else style="margin-top: 200px">
            <v-row justify="center" no-gutters class="fade-in">
                <v-col cols="12" md="10" lg="5" xl="4" class="text-center">
                    <v-img
                        class="mx-auto slide-in mb-5"
                        :src="require(`@/assets/images/group.png`)"
                        :max-height="$vuetify.breakpoint.xs ? 100 : 150"
                        contain
                    ></v-img>

                    <p class="subheading">
                        <i18n path="join.joinSuccess">
                            <span>{{ teamName }}</span>
                        </i18n>
                        <v-icon color="green">check</v-icon>
                    </p>

                    <i18n path="join.scheduled" style="font-size: 1.2rem">
                        <span>{{ new Date(surveyDate).toLocaleString() }}</span>
                    </i18n>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import { joinTeam, teamInfo } from "../api/onboarding.api";
import Configuration from "../services/configuration";

export default {
    name: "",
    props: [""],
    components: {},
    data: () => ({
        acceptTerms: false,
        companyName: "",
        teamName: "",
        teamToken: null,
        ownerFirstName: "",
        ownerLastName: "",
        surveyDate: "",
        showCarousel: true,
        isFormValid: false,
        joinComplete: false, //whether user has filled fields & joined successfully yet
        errorMessage: "",
        firstName: "",
        lastName: "",
        email: "",
        slides: [
            {
                text: "join.enterDetails",
                image: "pen-clean",
            },
            {
                text: "join.receive",
                image: "slide2",
            },
            {
                text: "join.share",
                image: "slide3",
            },
        ],
        notEmpty: [(v) => (v && !!v.trim()) || ""],
        emailRules: [
            (v) => !!v,
            (v) => /.+@.+\..+/.test(v) || "",
            (v) => (v || "").indexOf(" ") < 0 || "",
        ],
    }),
    methods: {
        join() {
            let joinRequest = {
                teamToken: this.teamToken,
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                language: this.$i18n.locale,
                acceptTerms: this.acceptTerms
            };
            joinTeam(joinRequest).then(
                (response) => {
                    // console.log("joinTeam: ", response.data);
                    if (response.data.surveyRunning) {
                        // reroute to Teambarometer
                        this.goToDashboard(response.data.loginLink);
                    } else {
                        // show info to user about successful join & future survey
                        this.surveyDate = response.data.surveyStart;
                        this.joinComplete = true;
                    }
                },
                (error) => {
                    this.errorMessage = error.response.data.message;
                }
            );
        },
        goToDashboard(link) {
            window.location = link;
        },
    },
    computed: {
        ownerFullName() {
            return this.ownerFirstName + " " + this.ownerLastName;
        },
        getToken() {
            return this.$route.query.token;
        },
    },
    mounted() {
        this.teamToken = this.$route.query.token;
        let tokenRequest = {
            token: this.teamToken,
        };
        teamInfo(tokenRequest).then(
            (response) => {
                // console.log("teamInfo: ", response.data);
                this.companyName = response.data.companyName;
                this.teamName = response.data.teamName;
                this.ownerFirstName = response.data.ownerFirstName;
                this.ownerLastName = response.data.ownerLastName;
            },
            (error) => {
                this.teamToken = null;
                this.errorMessage = error.response.data.message;
            }
        );
    },
};
</script>

<style></style>
