<template>
    <v-card class="mb-1 hoverQuestion" flat>
        <AvatarDemo
            :questionText="questionText"
            :showDemo="showDemo"
            :questionAvatar="question.icon"
            @demoStatus="showDemo = $event"
            @avatarChanged="changeAvatar($event)"
        />

        <v-card-text class="py-1 pr-2">
            <v-row no-gutters align="center">
                <v-col style="max-width:45px">
                    <v-img
                        :key="question.icon"
                        class="hover-pointer"
                        :src="
                            require(`@/assets/images/avatars/${question.icon}/5.png`)
                        "
                        max-width="42"
                        contain
                        @click="showDemo = true"
                    ></v-img>
                </v-col>
                <v-col style="max-width:25px;">
                    <div>{{ i + 1 }}.</div>
                </v-col>
                <v-col>
                    <div class="hover-drag">
                        <span>
                            {{ questionText }}
                        </span>
                    </div>
                </v-col>
                <v-col style="max-width:24px;" v-if="mode === 'build'">
                    <v-btn
                        :ripple="false"
                        @click="deleteQuestion(question)"
                        fab
                        x-small
                        text
                        color="grey lighten-1"
                    >
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import AvatarDemo from "@/components/AvatarDemo.vue";
export default {
    name: "",
    props: ["i", "question", "mode"], //mode can be either 'review' or 'build'
    components: { AvatarDemo },
    data: () => ({
        showDemo: false
    }),
    mounted() {},
    methods: {
        deleteQuestion(question) {
            this.$store.dispatch("questionpicker/deleteQuestion", question);
        },
        changeAvatar(avatar) {
            if (this.mode === "build") {
                this.$store.dispatch("questionpicker/setAvatar", [
                    avatar,
                    this.i
                ]);
            } else {
                // for review step icon change
                this.$emit("avatarChanged", [avatar, this.i]);
            }
        }
    },
    computed: {
        questionText() {
            let lang = this.$store.getters.questionDisplayLang;
            return this.question.questionText[lang];
        }
    }
};
</script>

<style></style>
