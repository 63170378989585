<template>
    <div class="fade-in">
        <p class="text-center subheading">{{ $t("interval.introText") }}</p>
        <v-img
            class="mx-auto pulse-once"
            :src="require(`@/assets/images/calendar.png`)"
            max-width="120"
            contain
        ></v-img>

        <v-row justify="center" >
            <v-col
                cols="12"
                sm="10"
                md="6"
                class="intervalSelect"
                align="center"
            >
                <v-menu
                    offset-y
                    transition="scale-transition"
                    origin="center center"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            :ripple="false"
                            v-on="on"
                            v-bind="attrs"
                            x-large
                            outlined
                            color="grey lighten-1"
                        >
                            <v-icon color="primary">event</v-icon>
                            <div
                                v-text="$t(interval.text)"
                                class="ml-1 btn-text black--text"
                            ></div>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in intervalOptions"
                            :key="index"
                            @click="interval = item"
                            class="transitioned"
                        >
                            <v-list-item-title
                                class="text-center"
                                v-text="$t(item.text)"
                            ></v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>

        <!--   <h1 v-if="pickerDate">test: {{ pickerDate | moment("DD-MM-YYYY") }}</h1>  -->

        <v-row justify="center" no-gutters >
            <v-col cols="12" class="text-center" sm="10" md="6">
                <p class="text-center subheading mt-7">
                    {{ $t("interval.whenStart") }}
                </p>

                <v-menu
                    transition="scale-transition"
                    class="mx-auto"
                    offset-y
                    origin="center center"
                    :nudge-left="50"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-show="!startNow"
                            :disabled="startNow"
                            :ripple="false"
                            v-on="on"
                            :x-large="!startNow"
                            outlined
                            color="grey lighten-1"
                        >
                            <v-icon color="primary">
                                mdi-flag-checkered
                            </v-icon>
                            <span class="ml-1 btn-text black--text">
                                {{ pickerDate | moment("DD-MM-YYYY") }}
                            </span>
                        </v-btn>
                    </template>
                    <v-date-picker
                        no-title
                        color="#2F53A0"
                        class="elevation-2"
                        width="350"
                        :min="minDate"
                        :max="maxDate"
                        :locale="$i18n.locale"
                        v-model="pickerDate"
                        :first-day-of-week="1"
                        :allowed-dates="allowedDates(0, 6)"
                    ></v-date-picker>
                </v-menu>
                <div v-show="startNow">
                    <v-btn disabled outlined color="grey lighten-1">
                        <v-icon color="primary">
                            mdi-flag-checkered
                        </v-icon>
                        <span class="ml-1 btn-text">
                            {{ pickerDate | moment("DD-MM-YYYY") }}
                        </span>
                    </v-btn>
                </div>

                <v-row justify="center">
                    <v-checkbox
                        :ripple="false"
                        v-model="startNow"
                        :label="$t('interval.iWish')"
                        hide-details
                    ></v-checkbox>
                    <v-tooltip bottom color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class="ml-1"
                                small
                                color="grey"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-information-outline
                            </v-icon>
                        </template>
                        <v-card class="pa-3" color="#F7EDF2">
                            {{ $t("interval.emails") }}
                        </v-card>
                    </v-tooltip>
                </v-row>
            </v-col>
        </v-row>

        <v-row justify="center" no-gutters class="mt-12" >
                <v-checkbox v-model="acceptTerms">
                    <template v-slot:label>
                        <div>
                            {{ $t("team.acceptTerms") }}
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a
                                        target="_blank"
                                        href="https://joineer.com/datenschutz/"
                                        @click.stop
                                        v-on="on"
                                    >
                                        {{ $t("team.terms") }}
                                    </a>
                                </template>
                                {{ $t("team.termsTooltip")}}
                            </v-tooltip>
                        </div>
                    </template>
                </v-checkbox>
        </v-row>

        <v-tooltip
            bottom
            :disabled="previousStepsCompleted && acceptTerms"
            color="transparent"
        >
            <template v-slot:activator="{ on }">
                <div v-on="on" class="text-center">
                    <base-btn
                        :loading="loading"
                        @click="finishRegistration"
                        :disabled="!previousStepsCompleted || loading || !acceptTerms"
                        :large="true"
                        :label="$t('buttons.finish')"

                    />
                </div>
            </template>

            <v-card v-if="!previousStepsCompleted" dark color="grey darken-1" >
                <v-card-text>
                    {{ $t("team.fillSteps") }}
                </v-card-text>
            </v-card>
            <v-card v-else-if="!acceptTerms" dark color="grey darken-1" >
                <v-card-text>
                    {{ $t("team.mustAcceptTerms") }}
                </v-card-text>
            </v-card>
        </v-tooltip>

        <div
            v-if="errorMessage"
            :class="{ 'width-400': $vuetify.breakpoint.smAndUp }"
            class="my-5 mx-auto"
        >
            <v-alert outlined type="error" text>
                {{ errorMessage }}
            </v-alert>
        </div>
    </div>
</template>

<script>
import { register } from "../api/onboarding.api";
export default {
    name: "Interval",
    props: [""],
    components: {},
    data: () => ({
        prodMode: true, //when false, no API call to register new user in backend
        loading: false,
        errorMessage: "",
        interval: "",
        startNow: false,
        acceptTerms: false,
        intervalOptions: [
            // { text: "interval.weekly", value: "WEEKLY" },
            { text: "interval.every2w", value: "BIMONTHLY" },
            // { text: "interval.every3w", value: "TRIMONTHLY" },
            { text: "interval.monthly", value: "MONTHLY" },
            { text: "interval.every6w", value: "BIQUARTERLY" },
            { text: "interval.every2m", value: "EVERY2MONTHS" },
            { text: "interval.every3m", value: "QUARTERLY" },
            { text: "interval.every4m", value: "EVERY4MONTHS" }
        ],
        pickerDate: null, //next working day by default
        minDate: null, //next working day
        maxDate: null // 1 year from now
    }),
    created() {
        this.interval = this.intervalOptions[3];
         this.setDates();
    },
    methods: {
        allowedDates: (a, b) => val =>
            ![a].includes(new Date(val).getDay()) &&
            ![b].includes(new Date(val).getDay()),
        finishRegistration() {
            if (this.startNow) {
                this.$store.commit("startNow", this.startNow);
                this.$store.commit("setInterval", this.interval.value);
            } else {
                this.$store.commit("setInterval", this.interval.value);
                this.$store.commit("setDate", this.pickerDate);
            }

            this.$store.commit("setTerms", this.acceptTerms);

            this.$store.commit("setQuestionList"); // put selected questions in the newUser object

            if (this.prodMode) {
                this.register();
            } else {
                this.$store.commit("setStep", [2, true]);
                this.$router.push({ name: "success" });
            }
        },
        register() {
            this.loading = true;
            this.errorMessage = "";
            register(this.$store.state.newUser)
                .then(
                    response => {
                        this.$store.commit(
                            "setRegisterResponse",
                            response.data
                        );
                        this.$store.commit("setStep", [2, true]);
                        this.$router.push({ name: "success" });
                    },
                    error => {
                        this.loading = false;
                        this.$store.commit("setStep", [2, false]);
                        this.errorMessage = error.response.data.message;
                        this.$vuetify.goTo(document.body.scrollHeight); //scroll bottom so that user definitely sees error in case it's out of viewport
                    }
                )
                .finally(() => (this.loading = false));
        },
        setDates() {
            // first retrieve today
            let defaultDate = new Date();

            //then calculate next day
            defaultDate.setDate(defaultDate.getDate() + 1);

            // then check which week day next day is (1 = Monday, 2 = Tuesday etc)
            let tomorrowDayNr = defaultDate.getDay();

            //set weekend rules
            if (tomorrowDayNr == 6) {
                //console.log("next day is saturday, setting date for next Monday");
                defaultDate.setDate(defaultDate.getDate() + 2);
            } else if (tomorrowDayNr == 0) {
                //console.log("next day is sunday, setting date for next Monday");
                defaultDate.setDate(defaultDate.getDate() + 1);
            }

            //set minimum date and default date in GUI to be next working day
            this.minDate = this.pickerFormat(defaultDate);
            this.pickerDate = this.pickerFormat(defaultDate);

            // set date 1 year from now as a max value for date picker
            let maxDate = new Date();
            maxDate.setFullYear(maxDate.getFullYear() + 1);
            this.maxDate = this.pickerFormat(maxDate);
        },
        pickerFormat(date) {
            //turns JS date object into a string in format of "YYYY-MM-DD"
            return date.toISOString().substr(0, 10);
        }
    },
    computed: {
        previousStepsCompleted() {
            // checks if questions are selected and team created
            let steps = this.$store.state.completionState;
            return steps[0] && steps[1];
        }
    },
    watch: {
        startNow(val) {
            if (!val) {
                this.setDates();
            } else {
                this.pickerDate = this.pickerFormat(new Date());
            }
        }
    }
};
</script>

<style>
.btn-text {
    text-transform: lowercase;
    font-weight: 400;
}

.width-400 {
    max-width: 400px;
}

.chooseDate {
    font-size: 14px;
}
</style>
