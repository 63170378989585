import axios from "axios";
import Configuration from "../services/configuration";

let API_BASE = '/api/public/';
const httpClient = axios.create({
    baseURL: Configuration.value('backendUrl') + API_BASE,
    timeout: 10000
});


export default httpClient;

