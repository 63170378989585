<template>
    <!-- shown when user has already selectedQuestions but goes back to step 1 at step/questions-overview -->
    <v-container>
        <v-row justify="center">
            <v-col class="pa-0" cols="12" lg="6">
                <v-row justify="space-between" class="mx-0">
                    <v-btn
                        outlined
                        color="grey"
                        @click="$router.push({ name: 'start' })"
                    >
                        {{ $t("buttons.backToSets") }}
                    </v-btn>
                    <base-btn
                        @click="$router.push({ name: 'team' })"
                        :label="$t('buttons.next')"
                    />
                </v-row>

                <v-card class="mt-2 pb-3" color="#F7F2F2">
                    <v-container>
                        <p class="text-center font-weight-bold">
                            {{ $t("questions.yourChosen") }}
                        </p>

                        <div v-if="selectedQuestions">
                            <v-alert
                                dismissible
                                v-model="infoBox"
                                dense
                                outlined
                                text
                                color="info"
                            >
                                <i18n
                                    path="questions.dragDrop"
                                    v-if="$route.name === 'questions-preview'"
                                >
                                    <v-icon small>mdi-drag-variant</v-icon>
                                    >
                                </i18n>
                                <i18n path="questions.dragReview" v-else>
                                    <v-icon small>mdi-drag-variant</v-icon>
                                    >
                                </i18n>

                                <i18n
                                    path="questions.clickPicture"
                                    class="ml-1"
                                >
                                    <span>
                                        <v-img
                                            :src="
                                                require(`@/assets/images/avatars/target/5.png`)
                                            "
                                            max-height="24px"
                                            max-width="24px"
                                            contain
                                            style="display: inline-block; margin-bottom: -5px"
                                        ></v-img>
                                    </span>
                                    >
                                </i18n>
                            </v-alert>

                            <draggable
                                class="list-group"
                                v-model="selectedQuestions"
                                v-bind="dragOptions"
                                @start="drag = true"
                                @end="drag = false"
                            >
                                <transition-group
                                    name="list-complete"
                                    tag="div"
                                >
                                    <SelectedQuestion
                                        class="list-complete-item"
                                        v-for="(question,
                                        i) in selectedQuestions"
                                        :key="`selectedOverview-${i}`"
                                        :question="question"
                                        :i="i"
                                        :mode="'build'"
                                    />
                                </transition-group>
                            </draggable>

                            <div class="text-center">
                                <div class="mt-10 text-caption">
                                    {{ $t("questions.changes") }}
                                </div>

                                <v-btn
                                    large
                                    @click="goEditQuestions"
                                    color="primary"
                                    text
                                    :ripple="false"
                                >
                                    <v-icon x-small class="mr-1">
                                        mdi-pencil
                                    </v-icon>
                                    {{ $t("questions.editQuestions") }}
                                </v-btn>
                            </div>
                        </div>

                        <v-card
                            v-else
                            flat
                            width="300"
                            class="text-center mx-auto pa-6"
                        >
                            {{ $t("questions.noQuestions") }}
                        </v-card>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import draggable from "vuedraggable";
import SelectedQuestion from "@/components/questionpicker/SelectedQuestion.vue";
export default {
    name: "",
    props: [],
    components: { draggable, SelectedQuestion },
    data: () => ({
        drag: false,
        infoBox: true
    }),
    computed: {
        completed() {
            return this.$store.state.completionState;
        },
        dragOptions() {
            return {
                animation: 200,
                disabled: false,
                group: "description",
                ghostClass: "ghost"
            };
        },
        selectedQuestions: {
            get() {
                return this.$store.state.questionpicker.selectedQuestions;
            },
            set(value) {
                // change order with drag/drop
                this.$store.dispatch("questionpicker/setList", value);
            }
        },
    },
    methods: {
        goEditQuestions() {
            this.$store.dispatch(
                "questionpicker/setList",
                this.selectedQuestions
            );
            this.$router.push({ name: "questions-build" });
        }
    }
};
</script>
